/* Add these CSS rules to style the horizontal calendar layout */
.horizontal-calendar-container .react-datepicker {
    width: auto !important;
    display: flex !important;
  }
  
  .horizontal-calendar-container .react-datepicker__month-container {
    float: none !important;
    margin-right: 20px;
  }
  
  .horizontal-calendar-container .react-datepicker__header {
    border-radius: 0.3rem 0.3rem 0 0;
  }
  
  .horizontal-calendar-container .react-datepicker__day--selected,
  .horizontal-calendar-container .react-datepicker__day--in-range {
      background-color: #F86855 !important; /* Selected date background */
      color: #FFF !important; /* Ensure the text color is white */
      border-radius: 50%; /* Make the selected date circular */
  }
  
  .horizontal-calendar-container .react-datepicker__day--keyboard-selected {
      background-color: #F86855 !important; /* Match the selected date background */
      color: #FFF !important;
      border-radius: 50%;
  }
  
  .horizontal-calendar-container .react-datepicker__day--in-selecting-range {
    background-color: var(--primary-color);
  }
  
  .horizontal-calendar-container .react-datepicker__day:hover {
    background-color: #F86855 !important; 
    color: white !important;
    border-radius: 50%; 
  }
  
  .checkInCalanderHotelDEtail {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    background: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }