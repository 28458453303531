.testimonialCont {
  padding: 2.5rem 0;
  margin-top: 2rem;
  background-color: var(--cream-color);
}
.testiListHotel {
  padding: 2.5rem 0;

  background-color: var(--cream-color);
}

.TestimonialData {
  position: relative;
  box-shadow: 0px 20px 50px 0px #0000000d;
  margin-bottom: 1rem;
  padding: 1.7rem 1.5rem;
  overflow: hidden;
  background-color: var(--white);
  border-radius: 12px;

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: var(--text-black);
  }
  p {
    margin-top: 1.2rem;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-grey);
  }
}
.testiHead {
  margin: 0px;
}

.testiImg {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.imagessss {
  height: 4rem;
  width: 4rem;
}
.rightTesthead {
  h1,
  p {
    margin: 0;
  }
}
