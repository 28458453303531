.closeBtnFIlter {
  position: absolute;
  top: -2rem;
  right: -3rem;

  button {
    height: fit-content;
    padding: 0.5rem;
    border-radius: 20px;
    border: none;
    background-color: transparent;

    img {
      padding: 5px;
      height: 29px;
      width: 28px;
      border-radius: 20px;
      background-color: var(--light-bg);

      &:hover {
        background-color: var(--white);
      }
    }
  }
}

@media screen and (max-width: 593px) {
  .closeBtnFIlter {
    right: -0.8rem;
    top: -3rem;
  }
}
