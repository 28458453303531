/* Modal overlay, position it absolutely */
.modal-overlay.option {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal content container */
.modal-content option{
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    /* width: 150px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Individual option style */
.modal-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    padding: 5px 0;
}

/* Icon styles */
.modal-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

/* Report text style */
.report-text {
    color: #E74C3C;
    font-size: 14px;
 
}

/* Feedback text style */
.feedback-text {
    color: #666;
    font-size: 14px;
    
}
/* Modal overlay */
/* Modal overlay */
.report-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content container */
.report-modal-content {
    overflow:auto;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
   max-height: 90vh;
    display: flex;
    flex-direction: column;
    /* gap: 15px; */
}

/* Modal heading */
.report-modal-heading {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

/* Details text */
.detailsTxt p {
    font-size: 14px;
    color: #666;
}

/* Section heading */
.headingh5 {
    font-size: 16px;
    color: #333;
}

/* Reasons container */
.reasons-container .custom-checkbox {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 15px;
}

.reason-text {
    display: flex;
    flex-direction: column;
}

.reason-title {
    font-weight: bold;
    font-size: 14px;
    color: #333;
    margin: 0;
}

.reason-description {
    font-size: 12px;
    color: #666;
    margin: 0;
}

/* Reason text area */
.reasonTextarea {
    width: 100%;
    height: 50px; /* Set a fixed height */
    padding: 10px;
    border: 1px solid #ddd; /* Black border */
    border-radius: 4px;
    resize: none; /* Disable resizing */
    overflow: hidden; /* Prevent inner scroll */
}
.reasonTextarea:focus {
    border-color: black; /* Black border when focused */
    outline: none; /* Remove the default outline */
}

/* Button container */
.continueBtn_container {
    display: flex;
    justify-content: flex-end;
}

/* Custom checkbox styling */
.custom-checkbox {
    display: flex;
    align-items: center;
}

.custom-checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
}

.checkbox-checkmark {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #181717;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.custom-checkbox input[type="checkbox"]:checked + .checkbox-checkmark {
    background-color: #E74C3C;
    border-color: #E74C3C;
}

.custom-checkbox input[type="checkbox"]:checked + .checkbox-checkmark::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: 1px;
    left: 5px;
}

/* Feedback Modal Overlay */
.feedback-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Feedback Modal Content */
.feedback-modal-content {
    background-color: #fff;
    padding: 20px;
    max-height: 90vh;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
 
}

/* Modal Heading */
.feedback-modal-heading {
    font-size: 20px;
    font-weight: bold;
    color: #333;

}

/* Hotel Information Section */
.feedback-header {
    display: flex;
    align-items: center;
    }

.feedbackhotelimage {
    width: 150px;
    height: 150px;
    margin-right: 20px;
}

.hotel-name {
    font-size: 18px;
    font-weight: 600;
    color: #444;
    margin: 0;
}

.hotel-address {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
}

/* Feedback Options */
.feedback-section h5 {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
}

.feedback-options {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.feedback-options button {
    font-size: 14px;
    border: none;
    background: none;
    cursor: pointer;
    padding: 5px 10px;
    position: relative;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.feedback-options button.selected {
    background-color: #fff;
    color: #333;
    box-shadow: 0 4px 8px rgba(240, 92, 92, 0.2);
    border-radius: 10px;
    font-weight: bold;
    padding: 10px 20px;
}
/* Emoji Icon Style */
.feedback-options button.selected::before {
    position: absolute;
    top: -30px; /* Position emoji above button */
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
}
.feedback-detail-section button.selected {
    background-color: #4C4C51;
    color: #fff;
    border-radius: 999px;
}

/* Feedback Text Area */
.feedback-textarea {
    width: 100%;
    height: 60px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 12px;
    resize: none;
}

/* Feedback Detail Section */
.feedback-detail-section h5 {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-top: 20px;
}

.feedback-detail-section button {
    display: inline-block;
    font-size: 12px;
    border: 1px solid #4C4C51;
    border-radius: 999px;
    padding: 5px 15px;
    margin: 5px;
    background-color: #fff;
    color: #4C4C51;
    cursor: pointer;
    transition: background-color 0.2s;
}

/* Submit Button */
.submit-btn-container {
    text-align: center;
    margin-top: 20px;
}

.submit-btn {
    font-size: 16px;
    background-color: #4C4C51;
    color: #fff;
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 999px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: #0056b3;
}
