.ListYourHotelCont {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: auto;
  height: calc(100vh - 75px);
  margin-bottom: 3rem;
  background-image: url("../../Assets/lsthtl.png");
  background-size: cover;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
}
.HeroLIstUnderHeading {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  span {
    font-size: 56px;
    font-weight: 700;
    text-align: start;
    color: var(--primary-color);
  }

  label {
    padding-top: 0.5rem;
    font-size: 20px;
    color: var(--white);
    font-weight: 400;
    text-align: start;
    width: 70%;
    text-align: center;
  }
}

.HeroLIstUnderHeading h1 {
  text-align: center;
  color: var(--white);
  font-weight: 700;
  font-size: 56px;
}
.processBtttn {
  margin-top: 1rem !important;

  height: 45px !important;
}
@media screen and (max-width: 768px) {
  .HeroLIstUnderHeading {
    label {
      width: 100%;
    }
  }
}

.OverListHeroHome {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.632);
}

.HeroBottmHeroContent,
.HeroBottmHeroContentHome {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 300;
  align-items: center;
  color: var(--white);
  position: absolute;
  z-index: -1;
  bottom: 0;
  position: relative;
  padding: 20px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  label {
    white-space: nowrap;
    font-size: 14px;
  }
}

.HeroBottmHeroContent {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
}

.heroUnderListHead {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0.5rem 0 0 0;
  align-items: center;
  position: relative;
  z-index: 2;
  height: 80%;
  justify-content: space-between;
}

.heroUnderListHead {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 56px;
    color: var(--white);
    margin: 0;
  }
  p {
    font-size: 56px;
    font-weight: 700;
    text-align: start;
    color: var(--primary-color);
  }
}

@media screen and (max-width: 991px) {
  .heroUnderListHead h1,
  .heroUnderListHead span {
    font-size: 48px !important;
  }
}

@media screen and (max-width: 576px) {
  .heroUnderListHead h1,
  .heroUnderListHead span {
    font-size: 36px !important;
  }
}

/* .ListHeroBottm {
  padding-top: 2.5rem;
} */
.increaseDiv {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--border-line-hotel);
  justify-content: space-between;

  .LeftIncreaseDiv {
    h2 {
      font-weight: 700;
      font-size: 24px;
      color: var(--text-black);
      line-height: 30px;
      margin: 0;
    }

    p {
      margin-top: 0.5rem;
      font-weight: 400;
      font-size: 20px;
      color: var(--text-grey);
      line-height: 30px;
    }
  }

  .RIghtIncreaseDiv {
    display: flex;
    width: 140px;
    height: 140px;
    padding: 1rem;
    justify-content: center;
    border-radius: 24px;
    background-color: var(--light-orenge);
    margin-bottom: 1rem;
    img {
      padding: 0.5rem;
      width: 100%;
      height: 100%;
    }
  }
}

.increaseVisiImgCOnt {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--border-line-hotel);

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    color: var(--text-black);
    line-height: 30px;
  }

  p {
    margin-top: 0.5rem;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: var(--text-grey);
  }

  .mobileImg {
    background-color: var(--light-orenge);
    border-radius: 12px;
    padding: 1rem;
    width: 140px;
    height: 140px;
    margin-bottom: 1rem;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

.DataIncre {
  padding: 1rem 28px;
}

.increseCnt {
  padding: 24px;
}
/*-----How it workd------*/
.howItworkDivHotel {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 3rem;
  background-color: #fff4f3;
  position: relative;
  overflow: hidden;
}

.buildingImg {
  position: absolute;
  bottom: 0;
  right: 200px;
  width: 600px;
  height: 300px;
  background-image: url("https://triosoft-storage.s3.us-west-2.amazonaws.com/swagstay-react-website/images/building.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 25px 0 0 25px;
}

.cloudesImg {
  position: absolute;
  bottom: -80px;
  width: 100%;
}

.howItWorksHotel__stepBox {
  margin-top: 0.5rem;
  height: 100%;

  h1 {
    background: linear-gradient(180deg, #f86855 0%, #fff4f3 94.3%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 80px;
    font-weight: 600;
  }
  h3 {
    font-size: 24px;
    font-weight: 700;
  }
  p {
    margin-top: 0.5rem;
    font-weight: 400;
    font-size: 20px;
    color: var(--text-grey);
  }
}

@media screen and (max-width: 1400px) {
  .buildingImg {
    right: 150px;
  }

  .cloudesImg {
    bottom: -50px;
  }
}

@media screen and (max-width: 1200px) {
  .buildingImg {
    right: 50px;
  }
}

@media screen and (max-width: 991px) {
  .howItWorksHotel__steps {
    margin-bottom: 5rem;
  }

  .cloudesImg {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }

  .DataIncre {
    border-radius: 12px !important;
    margin-bottom: 1.5rem;
    border: 1px solid var(--border-line-hotel);
  }

  .increaseDiv {
    border: none;
  }

  .increseCnt {
    border-radius: 12px !important;
    margin-bottom: 1.5rem;
    border: 1px solid var(--border-line-hotel) !important;
  }

  .increaseVisiImgCOnt {
    border: none !important;
  }
}

/*---------------Ready Bussiness-----------------*/
.ReadyBussiNessDiv {
  margin-top: 3rem;
}
.readyHead {
  h2 {
    font-weight: 600;
    margin: 0;
    color: var(--black);
    font-size: 36px;
  }
  P {
    margin-top: 0.5rem;
    font-weight: 400;
    color: var(--text-grey);
    font-size: 20px;
    line-height: 30px;
  }
}

.ListYourBssnssButton {
  padding: 0.5rem 1.5rem;
  color: var(--white);
  font-size: 15px;
  border-radius: 50px;
  border: transparent;
  background-color: var(--primary-color);
  margin-top: 1.5rem;
  &:hover {
    background-color: var(--primary-colorHover);
  }
}

.RIghtReadyDiv {
  display: flex;
  justify-content: flex-end;
  img {
    height: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .RIghtReadyDiv {
    justify-content: flex-end;
  }

  .RIghtReadyDiv img {
    width: 75%;
    height: 75%;
  }
}

.ReadyDivContent {
  justify-content: center;
  align-items: center;
}
.fontSizeSUbdiv {
  font-size: 16px;
}
.reasonTextarea:focus {
  border-color: black; /* Black border when focused */
  outline: none; /* Remove the default outline */
}

/*--------------------FOrm Data lisr hotel--------------------*/
.rightFOrm {
  label {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0.6rem;
  }

  input {
    padding: 0.6rem;
    font-size: 14px;
    box-shadow: none;
    outline: nine;

    &:hover {
      border: 1px solid var(--black);
    }

    &:focus {
      border: 1px solid var(--black);
      box-shadow: none;
    }
  }
  textarea {
    height: 100px;
    resize: none;
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    resize: none;
    overflow: hidden;
    &:hover {
      border: 1px solid var(--black);
    }

    &:focus {
      border: 1px solid var(--black);
      box-shadow: none;
    }
  }
}

.suFOrm {
  margin-top: 2rem;

  border: transparent;
  font-size: 16px;
  border-radius: 50px;
  padding: 0.5rem 1.8rem;
  color: var(--white);
  background-color: var(--Light-black);

  &:hover {
    background-color: var(--black);
  }
}

.listPropFOrm {
  align-items: center;
  img {
    max-height: 570px;
  }
}

.leftBorderLine {
  padding-left: 1rem;
  border-left: 1.5px solid var(--primary-color);
  display: flex;
  align-items: center;
  height: 60%;
}
.PaddingCLass {
  padding-left: 0.1rem;
}

.marginCLss {
  margin-top: 0.3rem !important;
}
@media all and (max-width: 768px) {
  .FormLftdata {
    display: none;
  }
}
@media all and (max-width: 1000px) {
  .FormLftdata {
    display: none;
  }
}
@media all and (min-width: 601px) and (max-width: 1000px) {
  .RIghtReadyDiv img {
    margin-left: 15%;
  }
}

/*---------------Mobile View Responsive-----------*/
@media all and (max-width: 600px) {
  .increseCnt {
    h2 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
  }

  .QuicklyHotl {
    p {
      font-size: 20px;
    }
    h2 {
      font-size: 24px;
    }
    .quickHotlsubhading {
      h2 {
        font-size: 24px;
      }

      span {
        font-size: 24px;
      }
    }
  }

  .coprtHowitwork {
    h1 {
      font-size: 32px !important;
    }
    h3 {
      font-size: 20px !important;
    }
    p {
      font-size: 16px !important;
    }
  }

  .readyHead {
    p {
      font-size: 16px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .increaseDiv {
    .LeftIncreaseDiv {
      h2 {
        font-size: 18px;
        line-height: 24px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .RIghtIncreaseDiv {
      width: 80px;
      height: 80px;
    }
  }

  .increaseVisiImgCOnt {
    .mobileImg {
      width: 80px;
      height: 80px;
    }
  }

  .increaseVisiImgCOnt {
    h2 {
      font-size: 18px;
      line-height: 24px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }

    .mobileImg {
      background-color: var(--light-orenge);
      border-radius: 12px;
      padding: 1rem;
      width: 140px;
      height: 140px;
      margin-bottom: 1rem;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .CopratImgDIv {
    width: 100px;
    height: 100px;
  }
}
