.heroCoprateSolution {
  background-image: url("../../Assets/copreCoverHero.svg");
}

.copSolHead {
  p {
    font-family: "Satoshi";
  }
}
.COpCOntUsClass {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 16px;
}
.CopHotlSubHead {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.TailHead h1 {
  width: 70%;
  margin: auto;
  text-align: center;
  color: var(--white);
  font-weight: 700;
  font-size: 56px;
}

@media screen and (max-width: 768px) {
  .TailHead {
    h1 {
      width: 100%;
    }
  }
}

.heaCop {
  button {
    margin-top: 2.1rem;
    padding: 0.5rem 1.5rem;
    border-radius: 50px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    height: 56px;

    &:hover {
      background-attachment: var(--cream-color);
    }
  }
}

.dataCopreStay,
.ContnrCoprateStay {
  border: transparent;
}
.dataCopreStay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 24px;

  p {
    text-align: center;
  }
}

@media screen and (max-width: 1200px) {
  .dataCopreStay {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.coprtHowitwork {
  margin-top: 0.5rem;

  h1 {
    border-radius: 50px;
    padding: 1rem;

    color: var(--black);
    background-color: var(--white);
    font-size: 40px;
    font-weight: 400;
    height: 80px;
    width: 80px;
    text-align: center;
  }
  h3 {
    font-size: 24px;
    font-weight: 700;
    color: var(--text-black);
  }
  p {
    margin-top: 0.5rem;
    font-weight: 400;
    font-size: 20px;
    color: var(--text-grey);
    line-height: 30px;
  }
}

.horIzontlist {
  height: 2px;
  width: 98%;
  margin: 1.3rem 0rem;
  border-radius: 20px;
  background-color: var(--primary-color);
}

.CopratImgDIv {
  background-color: var(--light-orenge);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 80%;
    width: 80%;
  }
}

@media screen and (min-width: 1200px) {
  .stepbyStep {
    width: 90%;
  }
}
