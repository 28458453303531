.WhyChooseContainer {
  padding: 1.5rem 0rem;
}

.ourserviceDiv {
  p {
    color: var(--white);
    font-weight: 500;
    font-size: 22px;
    margin: 0;
  }
  h2 {
    font-weight: 400;
    margin: 0;
    color: var(--white);
  }
}

.whyChoose-item {
  background-color: var(--cream-color);
  border-radius: 12px;
  padding: 0.7rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  clip-path: polygon(
    20% 0%,
    100% 0,
    100% 20%,
    100% 81%,
    89% 100%,
    20% 100%,
    0 100%,
    0 0
  );

  h2 {
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: 22px;
    color: var(--black);
    font-weight: 700;
  }
  p {
    text-align: left;
    margin: 0;
    padding-top: 6px;

    font-size: 13px;
    font-weight: 500;
  }
}

.smallIcn {
  padding: 1rem;
  img {
    height: 23px;
    width: 23px;
  }
}
.chooMapdata {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}
/* @media screen and (max-width: 767px) {
    .service-item {
      width: 100%;
    }
  } */
