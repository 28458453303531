.qrContCover {
  padding: 2rem 0px;
  background-image: url("../../Assets/discountSection.svg");
  background-size: cover;
  background-position: center;
}

.discountSec {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  h2 {
    font-weight: 600;
    text-align: left;
  }
  p {
    font-weight: 600;
    text-align: left;
  }
}
.LeftSideDis {
  gap: 0.6rem;
  img {
    height: 200px;
  }
  h1 {
    font-weight: 700;
    color: var(--dark-green);
    font-size: 64px;
  }
}

.percentageDiscount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    margin: 0;
  }
}
.RightSideDis {
  justify-content: center;
  align-items: center;
}

.discountTxts h2 {
  font-size: 36px;
  font-size: 700;
  color: var(--text-black);
}

.discountTxts p {
  font-size: 24px;
  color: var(--text-grey);
}

@media screen and (max-width: 991px) {
  .discountTxts h2 {
    font-size: 30px;
  }

  .discountTxts p {
    font-size: 18px;
  }

  .LeftSideDis h1 {
    font-size: 44px;
  }

  .qrContCover {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .RightSideDis {
    justify-content: flex-start;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .discountTxts h2 {
    font-size: 24px;
  }

  .discountTxts p {
    font-size: 16px;
  }

  .LeftSideDis h1 {
    font-size: 32px;
  }

  .LeftSideDis img {
    height: 150px;
  }

  .qrContCover {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  .RightSideDis {
    justify-content: flex-start;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .LeftSideDis {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .LeftSideDis h1 {
    font-size: 64px;
  }

  .LeftSideDis img {
    height: 200px;
  }
}
