.share-popup-modal {
  outline: none;
  border-radius: 12px;
  overflow: hidden;
}

.share-popup {
}

.share-popup h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
}

.share-icons {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.share-popup-modal .iconShareP {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #555;
  cursor: pointer;
}

.iconShareP img {
  width: 48px;
  height: 48px;
  margin-bottom: 5px;
  border-radius: 50%;
  background-color: #f0f0f0;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.share-link {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  bottom: 0;
  margin-top: 10px;
}

.share-link input {
  width: 100%;
  border: none;
  padding: 10px;
  font-size: 14px;
  color: #555;
  background-color: #f9f9f9;
  text-align: left;
}

.share-link input:focus {
  outline: none;
}

.share-link button {
  background-color: #ff6b6b;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 0 8px 8px 0;
  font-weight: bold;
}

.share-link button:hover {
  background-color: #ff4c4c;
}
