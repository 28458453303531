.sideoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -350px;
  width: 350px;
  height: 100vh;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.2s ease-in-out;
  z-index: 1100;
}

.sidebar.open {
  right: 0;
}

.Sideclose-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.proEditSIde {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  gap: 0.1rem;
  padding-left: 0;
}

.arrowImgSide {
  width: 16px;
  height: 16px;
  transform: rotate(-90deg);
}

.proEditSIde p {
  margin: 0;
  color: var(--white);
}

.sidebarTop {
  background-color: var(--primary-color);
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.proImgSide {
  width: 80px;
  height: 80px;
}

.sidePRofile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.proName {
  color: var(--white);
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin: 0;
}

.sideClose {
  height: 100%;
  display: flex;
  align-items: flex-start;
}

.closeImgSide {
  width: 18px;
  height: 18px;
}

.sidebarBottom {
  padding: 1rem;
}

.sideListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.arrowListSide {
  width: 16px;
  height: 16px;
}

.sideListItem p {
  color: var(--text-grey);
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.selectedListItem {
  background-color: var(--light-bg);
}

.selectedListItem p {
  color: var(--primary-color) !important;
}

.sidebarBottom {
  height: calc(100vh - 130px);
  overflow-y: scroll;
}

.sideHead {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: var(--text-black);
}

.sideSubHead {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-grey);
  text-align: center;
}

.appandHotelCount p {
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-color);
  margin: 0;
}

.appandHotelCount img {
  width: 20px;
  height: 20px;
}

.appandHotelCount {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.SwagQR {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.SwagQRImg {
  width: 140px;
  height: 140px;
}

.SwagQR p {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-grey);
  margin: 0;
}

.SwagQR span {
  font-size: 14px;
  font-weight: 700;
  color: var(--primary-color);
  margin: 0;
}

.sidebarFooter {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
