.backWrapper {
  padding: 1rem 0;
  background-color: var(--light-bg);
  z-index: 0;
  position: relative;
}

.backCOntainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.backImg {
  width: 24px;
  height: 24px;
  margin-top: -2px;
}

.backName {
  font-weight: 600;
  margin: 0;
  color: var(--text-black);
}
