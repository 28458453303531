.IncluncerHero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  width: 100%;
  overflow: hidden;
  margin-bottom: 3rem;
  background-color: var(--cream-color);
  background-size: cover;
  background-position: center;
  position: relative;

  h1 {
    font-weight: 700;

    color: var(--black);
  }
  p {
    text-align: start;
  }
  label {
    text-align: start;
    color: var(--black);
  }
}

.WHoJoinInfluencer {
  label {
    font-weight: 400;
    color: var(--text-black);
    font-size: 20px;
  }
}
.marginclsrem {
  margin-top: 1rem;
}
.InflucenerBox {
  height: 100%;

  h1 {
    font-size: 50px;
  }
  p {
    font-weight: 400;
    font-size: 20px;
  }

  .infcicle {
    position: absolute;
    top: -40%;
    right: -10%;
    width: 200px;
    height: 165px;
  }
}

.ApplyNowBtnInflucr {
  background-color: var(--Light-black);
  margin-top: 1rem;
  font-size: 16px;
  &:hover {
    background-color: var(--black);
  }
}

.rightContInclucenrerUnlockEarning {
  h2 {
    font-weight: 700;
  }
  p {
    font-weight: 400;
    color: var(--text-grey);
    font-size: 20px;
  }
}

.unlockedIncluncerDiv {
  background-image: url("../../Assets/unlockCoverBgInfluncer.svg");
  background-repeat: no-repeat;
  background-size: cover;

  .ReadyDivContent {
    padding: 1rem;
  }
}

/* .joinPrograamBtn {
  border: none;
  width: fit-content;
  font-size: 14px;
  font-weight: 600;
  border-radius: 20px;
  padding: 0.3rem 2rem;
  color: var(--white);
  background-color: var(--Light-black);
  margin-top: 2rem;
  &:hover {
    background-color: var(--black);
  }
} */

.JoinTHprogrm {
  margin-top: 1.5rem;
  font-weight: 600;
  padding: 0.5rem 2rem;
  width: fit-content;
}

.LEftInfluncer {
  display: flex;

  padding: 3rem 1rem;

  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  h1 {
    font-weight: 700;
    font-size: 56px;
  }

  p {
    font-weight: 700;
    font-size: 56px;
    color: var(--primary-color);
  }

  label {
    font-weight: 400;
    color: var(--text-grey);
    font-size: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .LEftInfluncer {
    h1 {
      font-size: 48px;
    }

    p {
      font-size: 48px;
    }

    label {
      font-size: 20px;
      text-align: center;
    }
  }
}

.InfluncerPatnerDiv {
  padding: 24px;
  border-radius: 12px !important;
  margin-bottom: 1.5rem;
}
.influncerImgLft {
  height: 100%;
  width: 100%;
  object-fit: none;
}
.heroRIghtInfluncer {
  height: 100%;
  display: flex;
  padding-top: 2rem;
  justify-content: center;
  align-items: center;
  img {
    position: absolute;
    bottom: 0;
    object-fit: contain;
  }
}
.socialIconinputCont1,
.socialIconinputCont2,
.socialIconinputCont3,
.socialIconinputCont4 {
  position: relative;
  margin-right: 0.2rem;
  margin-bottom: 0.7rem;

  input {
    padding-left: 40px !important;
  }
}
.displayflex {
  display: flex;
  align-content: space-between;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}

.socialIconinputCont1::before,
.socialIconinputCont2::before,
.socialIconinputCont3::before,
.socialIconinputCont4::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
}
.socialIconinputCont1::before {
  background-image: url("../../Assets/fbIcn.svg");
}

.socialIconinputCont2::before {
  background-image: url("../../Assets/instagrmmcn.svg");
}

.socialIconinputCont3::before {
  background-image: url("../../Assets/TwittrIcn.svg");
}

.socialIconinputCont4::before {
  background-image: url("../../Assets/YttIcn.svg");
}

/*------------------Responsive Mobile and Tab View ------------------*/

@media screen and (max-width: 991px) {
  .LEftInfluncer {
    margin-top: 3rem;
  }

  .heroRIghtInfluncer {
    flex-direction: column;
    img {
      position: none;
      width: 100%;
      height: 350px;
    }
  }
}

@media screen and (max-width: 576px) {
  .LEftInfluncer {
    margin-top: 0;

    h1 {
      text-align: center;
    }
  }

  .heroRIghtInfluncer {
    flex-direction: column;
    img {
      position: none;
      width: 100%;
      height: 250px;
    }
  }
}

@media all and (max-width: 768px) {
  .IncluncerHero {
    align-items: start;
  }
}

@media all and (min-width: 1401px) {
  .WHoJoinInfluencer {
    margin-top: 11%;
  }
}
@media all and (min-width: 767px) and (max-width: 910px) {
  .ApplyNowBtnInflucr {
    margin-bottom: 4rem;
  }
}
@media all and (min-width: 601px) and (max-width: 1000px) {
  .LEftInfluncer {
    align-items: center;
    h1 {
      font-size: 48px;
    }

    p {
      font-size: 48px;
    }

    label {
      font-size: 20px;
    }
  }
}
@media all and (max-width: 600px) {
  .LEftInfluncer {
    align-items: center;
    h1 {
      font-size: 36px;
    }

    p {
      font-size: 36px;
    }

    span {
      font-size: 36px;
      color: var(--primary-color);
    }

    label {
      font-size: 20px;
    }
  }

  .InfluncerPatnerDiv {
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }

  .WHoJoinInfluencer {
    label {
      display: none;
    }
  }

  .InflucenerBox {
    p {
      font-size: 16px;
    }
  }
}
