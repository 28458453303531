.WelcomeheroSection {
  max-height: 400px;
  margin-bottom: 3rem;
  padding: 4rem 2rem;
  height: 100%;
  border-radius: 15px;
  position: relative;
  background-image: url("../../Assets/welComeBunusCOver.svg");
  background-size: cover;
  background-position: center;

  h1 {
    font-size: 45px;
    font-weight: 600;
    text-align: left;
    color: var(--primary-color);
  }
  p {
    white-space: wrap;
    color: var(--black);
    font-size: 30px;
    text-align: left;
    margin: 0;
    font-weight: 700;
  }
}

.WelcomunderText {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  align-items: center;
  h1 {
    font-weight: 700;
    margin: 0;
    font-size: 30px;
  }
}

.getStartText {
  margin-top: 4rem;
  margin-bottom: 1.5rem;

  h2 {
    font-weight: 600;
  }
}

.MobileCelebrateImg {
  justify-content: end;
  height: 100%;
  width: 100%;
  right: 5px;
  bottom: 10px;
  display: none;

  img {
    height: 120px;
    width: 120px;
  }
}

@media screen and (max-width: 768px) {
  .WelcomeheroSection {
    background-image: url("../../Assets/MobilewelComeBunusCOver.svg");
  }
  .MobileCelebrateImg {
    display: flex;
  }

  .WelcomeheroSection {
    max-height: 100%;
  }
}
@media screen and (max-width: 555px) {
  .WelcomeheroSection {
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 32px;
    }
  }
  .WelcomunderText {
    font-size: 27px;
    h1 {
      font-size: 32px;
    }
  }
}
