.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-icon {
    width: 40px;
    margin-bottom: 10px;
}

.popup-content h4 {
    color: red;
    margin: 0;
    font-size: 15px;
    font-weight: bold;
}

.popup-content p {
    font-size: 13px;
    color: gray;
    margin: 10px 0 20px;
}

.popup-buttons {
    display: flex;
    justify-content: space-around;
}

.popup-button {
    width:79px;
    height: 40px ;
    border: none;
    border-radius: 999px;
    cursor: pointer;
    font-size: 12px; /* Set font size for both buttons */
}

.popup-button.confirm {
    background-color: #4C4C51;
    color: white;
}

.popup-button.cancel {
    color: black;
    background-color: white;
    border: 1px solid #4C4C51; /* Add border to Cancel button */
}