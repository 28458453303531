/*---------------Sort BY--------------------------------*/

.shorByContent {
  background-color: var(--light-bg);
  padding: 0.3rem 0.9rem;

  .shortByListadat {
    display: flex;
    gap: 1rem;
    align-items: center;
    overflow: auto;
    padding: 0.8rem 0;
    h1 {
      white-space: nowrap;
      margin: 0;
      font-size: 22px;
      font-weight: 600;
    }
  }

  .sortBybttn {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    button {
      white-space: nowrap;
      transition: background-color 0.3s ease, color 0.3s ease;

      font-size: 14px;
      font-weight: 400;
      color: var(--text-grey);
      border: 1px solid var(--border-line-hotel);
      padding: 0.5rem 0.9rem;
      border-radius: 50px;
      background-color: transparent;

      &:hover {
        background-color: var(--Light-black);
        color: var(--white);
      }
      &.activesortby {
        background-color: var(--Light-black);
        color: var(--white);
      }
    }
  }
}
