.shimmer {
  /* padding: 5px; */
  width: 95%;
  height: 120px;
  margin: 10px auto;
  background: #ffffff;
}
.shimmerContainer,
.shimmerContainerBooking {
  height: 100%;

  width: 100%;
  position: fixed;
  background: #ffffff;
  z-index: 400;
}
.shimmerContainer {
  top: 0;
}
.shimmerContainerBooking {
  top: 35;

  .shimmerBG {
    display: none;
  }
}
.shimmer .image-card {
  display: none;
  height: 90px;
  width: 90px;
  float: right;
  border-radius: 8px;
}

.shimmerBG {
  height: 35vh;
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
}

.stroke {
  height: 15px;
  background: #777;
  margin-top: 20px;
}

.wrapper {
  width: 0px;
  animation: fullView 0.5s forwards linear;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}
.animate {
  animation: shimmer 3s;
  animation-iteration-count: infinite;
  background: linear-gradient(to right, #e6e6e6 5%, #cccccc 25%, #e6e6e6 35%);
  background-size: 1000px 100%;
}
@keyframes shimmer {
  from {
    background-position: -1000px 0;
  }
  to {
    background-position: 1000px 0;
  }
}
