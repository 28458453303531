.percentecontainr {
  position: relative;
  border-radius: 24px;
  height: 100%;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 24px;
  }
}

.whatisnewData {
  display: flex;
  align-items: center;
}
.percenUnder {
  display: flex;
  flex-direction: column;
  z-index: 22;
  position: absolute;
  bottom: 13%;
  left: 11%;

  label {
    font-weight: 600;
    color: var(--white);
    font-size: 25px;
  }
}

.stayIndiv,
.curlText {
  h2,
  span {
    margin: 0;
    font-size: 17px;
    font-weight: 600;
  }
}
.BookSTy {
  margin-top: 5px !important;
  font-weight: 400 !important;
  color: var(--text-grey) !important;
}
.staycontainer {
  padding: 1rem;
  background-color: var(--cream-color);
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-radius: 24px;
  position: relative;
  height: 100%;
}
.imgStay {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
  img {
    height: 100%;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
  }
}

.curlLIne {
  height: 100%;
  width: 100%;
  border-radius: 24px;
  background-color: var(--cream-color);
  padding: 0.7rem 2.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.lineCUrl {
  padding-top: 0.7rem;
  height: 70px;
  width: 70px;
}
.coinImg {
  margin-top: 10px;
  position: absolute;
  bottom: 0;
  height: 50%;
}

.rowGap {
  gap: 1rem;
}

.h1HeadNew {
  margin: 0;
  font-size: 32px !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 991px) {
  .h1HeadNew {
    margin: 0;
    font-size: 28px !important;
    font-weight: 700 !important;
  }

  .coinImg {
    height: 30%;
  }
}

@media screen and (max-width: 850px) {
  .h1HeadNew {
    margin: 0;
    font-size: 24px !important;
    font-weight: 700 !important;
  }

  .coinImg {
    height: 30%;
  }

  .curlLIne {
    padding: 0.7rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .padRB0 {
    padding-right: 0;
  }
  .padLB0 {
    padding-left: 0;
  }

  .magin1 {
    margin-bottom: 24px;
  }

  .curlLIne {
    padding: 0.7rem 1rem;
  }
}

@media screen and (max-width: 576px) {
  .padRB0 {
    padding: 0;
  }
  .padLB0 {
    padding: 0;
  }

  .magin1 {
    margin-bottom: 24px;
  }

  .curlLIne {
    padding: 0.7rem 1rem;
    height: 300px;
  }

  .whatnewRightdata {
    margin-bottom: 32px;
  }

  .gap576 {
    gap: 16px !important;
  }
}
