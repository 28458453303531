.heroSection {
  max-height: 400px;
  margin-bottom: 3rem;
  padding: 4rem 2rem;
  border-radius: 15px;
  background-image: url("../../Assets/expressHero.svg");
  background-size: cover;
  background-position: center;
  margin-top: 2rem;

  h1 {
    font-size: 45px;
    font-weight: 600;
    text-align: left;
    color: var(--white);
  }
  p {
    font-size: 30px;
    text-align: left;
    margin: 0;
    font-weight: 600;
  }
}
