.botttmFaildData {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  button {
    margin-top: 0.4rem;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 400;
    border: 1px solid var(--black);
    padding: 0.5rem 2rem;
    background-color: transparent;
    width: fit-content;
  }

  img {
    height: 50px;
    width: 50px;
  }
}
.FaildTextt_P {
  font-size: 15px;
  font-weight: 500;
  color: var(--text-grey);
  padding-bottom: 0.5rem;
  margin: 0;
}

.FaildTextt {
  font-weight: 600;
  font-size: 25px;
  color: var(--text-grey);
}
.Faild img {
  padding: 1.5rem;
  height: 70px;
  width: 70px;
  background-color: var(--red);
}

.circlesCutted {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.cutCir1,
.cutCir2,
.cutCir3,
.cutCir4,
.cutCir5,
.cutCir6,
.cutCir7,
.cutCir8 {
  position: absolute;
  bottom: -1rem;

  padding: 1rem;
  border-radius: 50px;
  background-color: var(--light-blue);
}

.cutCir1 {
  left: 1rem;
}
.cutCir2 {
  left: 4rem;
}
.cutCir3 {
  left: 7rem;
}
.cutCir4 {
  left: 10rem;
}
.cutCir5 {
  left: 13rem;
}
.cutCir6 {
  left: 16rem;
}
.cutCir7 {
  left: 19rem;
}
.cutCir8 {
  left: 22rem;
}
