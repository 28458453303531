.bookingReqHero {
  border-radius: 12px;
  margin-top: 2rem;

  padding: 2rem 2rem;

  background-image: url("../../Assets/bookingRequestHeroImg.svg");
  background-size: cover;
  background-repeat: no-repeat;

  h1 {
    font-size: 24px;
    font-weight: 700;
    color: var(--white);
    padding-bottom: 0.5rem;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    color: var(--white);
  }
}

.FormDiv {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: 1px solid var(--border-line-hotel);
  border-radius: 12px;
  padding: 2rem;

  .headBookinReq {
    h1 {
      font-size: 35px;
      font-weight: 700;
    }
  }

  label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  span {
    color: var(--red);
  }

  .form-control {
    padding: 0.8rem;
    border-radius: 8px;
    border: 1px solid var(--border-line-hotel);
    color: var(--black);

    &::placeholder {
      color: var(--text-grey);
    }
  }
  .form-control:focus {
    box-shadow: none;
    border: 1px solid var(--primary-color);
  }
  .form-group {
    margin-top: 1.3rem;
  }

  /* .gpCLass {
    gap: 8px;
  } */

  /*--------------Adult Section----------------*/

  .containerAdultDIv {
    display: flex;

    gap: 1.3rem;
    justify-content: space-between;
    width: 100%;
  }
  .adultsDIv {
    display: flex;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--border-line-hotel);

    .leftDiv {
      label {
        font-size: 16px;
        font-weight: 400;
        color: var(--text-grey);
        margin: 0px;
      }
    }
    .incmtnDecrement {
      display: flex;

      align-items: center;
      label {
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        width: 28px;
        margin: 0px;
      }
      button {
        text-align: center;
        border-radius: 20px;
        padding: 1px 8px;
        height: fit-content;
        width: fit-content;
        border: 1px solid var(--border-line-hotel);
      }
    }
  }

  /*------------------Select Date----------------------*/

  .dateSHowDrps .checkInData,
  .dateSHowDrps .checkOutData {
    display: flex;
    align-items: center;
  }

  .FormDiv .adultsDIv,
  .FormDiv .dateSHowDrps {
    display: flex;
    align-items: center;
  }

  .dateSHowDrps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    gap: 1.3rem;
    .checkInData,
    .checkOutData {
      width: 100%;
      white-space: nowrap;
      cursor: pointer;
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding: 0.8rem 1rem;
      border: 1px solid var(--border-line-hotel);
      p {
        margin: 0px;
      }
      .date-section {
        display: grid;
        position: relative;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
      }
      .dateTxt {
        font-size: 14px;
        color: var(--text-grey);
        font-weight: 400;
        margin: 0;
      }
      img {
        height: 15px;
        width: 15px;
      }

      &:hover {
        border: 1px solid var(--primary-color);
      }
      @media all and (max-width: 596px) {
        .checkInCalanderHotelDEtail {
          flex-direction: column;
          left: 1rem;
          bottom: -34.6rem;
        }
      }
    }
  }

  /*-----------------Submit Data ----------------------*/
  .SUbmnttttn {
    width: 100%;
    margin-top: 3rem;
    padding: 0.7rem;
    border: none;
    border-radius: 25px;
    color: var(--white);
    background-color: var(--Light-black);
    font-size: 16px;
    font-weight: 500;
    &:hover {
      background-color: var(--black);
    }
  }
}

/*----------------AFter booking request--------*/
.afterBookingCOnfirmDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
  height: 50vh;
  overflow: hidden;
  border-radius: 12px;
  background-image: url("../../Assets/subReBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .SubmitRequ {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 1.5rem;

    .imgCicle {
      height: 70px;
      width: 70px;
    }
    h1 {
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: var(--primary-color);
    }
  }
}

@media all and (max-width: 555px) {
  .FormDiv {
    padding: 2rem 1rem;
    .dateSHowDrps {
      flex-wrap: wrap;
    }
  }
}
@media all and (max-width: 1200px) {
  .FormDiv {
    .containerAdultDIv {
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 630px) {
  .gridNone {
    grid-template-columns: none !important;
  }
}
