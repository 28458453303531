.refundBookId p,
.refundBookId span {
  font-size: 16px !important;
}

.refundBookTop {
  border: none !important;
}

.refundBookBottom {
  display: flex;
  align-items: center;
}

.refundCard {
  background-color: var(--white);
  border: 1px solid var(--border-line-hotel);
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 576px) {
  .refundBookId p,
  .refundBookId span {
    font-size: 14px !important;
  }

  .cancelRefundP {
    line-height: 5px;
  }
}
