.blogHEroSection {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  font-family: "inter";
  padding: 2rem 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "inter";
  h1 {
    font-size: 63px;
    font-weight: 700;
    color: var(--black);
  }

  p {
    font-size: 15px;
    font-weight: 400;
    color: var(--text-grey);
  }
}

@media screen and (max-width: 768px) {
  .blogHEroSection {
    h1 {
      font-size: 48px;
      text-align: center;
    }

    p {
      font-size: 15px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .blogHEroSection {
    h1 {
      font-size: 36px;
      text-align: center;
    }

    p {
      font-size: 15px;
    }
  }

  .AboutHeroSec {
    height: 50vh;
  }
}

.EMailDicBlogCOnter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.EMailDicBlog {
  display: flex;
  padding: 0.4rem;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  align-items: center;

  border-radius: 12px;
  border: 1px solid var(--border-line-hotel);
  background-color: var(--cream-color);
  input {
    border: none;
    font-size: 15px;
    padding-left: 1rem;
    background-color: var(--cream-color);
    width: 100%;
    &:focus {
      background-color: transparent;
      border: transparent;
      outline: none;
      box-shadow: none;
    }
  }
}

.SubDivv {
  display: flex;
  justify-content: flex-end;
  button {
    font-size: 14px;
    background-color: var(--Light-black);
    color: var(--white);
    border: none;
    padding: 0.6rem 1.8rem;
    border-radius: 20px;

    &:hover {
      background-color: var(--black);
    }
  }
}

/*--------recent Blog Div-----------*/

.RecentBLogDiv {
  margin-top: 3.4rem;
}

.RecentBlogHead {
  margin-bottom: 2rem;
  h1 {
    font-weight: 700;
    font-size: 33px;
  }
}
.blogHeroSection {
  p {
    color: #6c757d;
  }
}

.emailSection {
  input[type="text"] {
    padding: 0.5rem;
  }
}

.blogcard {
  border: none;
  padding: 0.5rem;
  border-radius: 12px;
  overflow: hidden;
  margin: auto;
  max-width: 400px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

    /* transform: scale(1.05); */
  }

  .blogcard-title {
    font-weight: bold;
  }

  .blogcard-text {
    color: #6c757d;
  }
  .blogcard-body {
    margin-top: 1rem;
  }
}

.blogcard img {
  width: 100%;
  border-radius: 12px;
  aspect-ratio: 1.7 / 1;
  object-fit: cover;
}

.blogReadmore {
  text-align: start;
  font-weight: 500;
  border: none;
  background-color: transparent;
  color: var(--blue-color);
  font-size: 16px;
  transition: 0.4s ease;

  &:hover {
    color: var(--blue-color-hover);
  }
}

/*-------Tranding Blog------*/

.TrandingBlogg {
  background-color: var(--light-bg);
  margin-top: 2.5rem;

  .TradHead {
    padding-top: 1.5rem;
  }
}

.showoreBlogDiv {
  display: flex;
  justify-content: center;
  width: 30%;
  margin: 3rem auto;
}
