.SuccCOntent {
  width: 100%;
  display: flex;
  font-family: "inter";
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 30px;
    color: var(--Light-black);
    font-family: "inter";
    font-weight: 700;
  }
  p {
    padding-top: 0.2rem;
    font-size: 16px;
    font-weight: 400;
    color: var(--text-grey);
  }
}

.contentContainerSUccesModal {
  padding: 2.5rem 0rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    height: 125px;
    width: 125px;
  }
}
