.ReferEarnContainer {
  margin-top: 4rem;
  padding: 2rem 0 0 0;
  border-radius: 24px;
  background-color: #fff4f3;
  position: relative;
}

.rightRefer {
  display: flex;
  justify-content: flex-end;
  img {
    width: 100%;
  }
}
.ReferHeadig {
  display: flex;
  align-items: center;
  gap: 5px;
  h1 {
    font-size: 22px;
  }
}
.referCOntent {
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 58px;
    font-weight: 700;
  }
  p {
    /* width: 57%; */
    font-weight: 300;
    font-size: 24px;
    color: var(--text-grey);
  }
}

@media screen and (max-width: 767px) {
  .rightRefer {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
  }
}

.referHeading {
  p {
    color: var(--text-black);
    font-weight: 700;
    font-size: 35px;
    margin: 0;
  }
  h2 {
    font-weight: 400;
    margin: 0;
    color: var(--text-grey);
    font-size: 18px;
  }
  span {
    font-weight: 700;
    margin: 0;
    color: var(--primary-color);
    font-size: 35px;
  }
}

.cloudsBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

@media screen and (max-width: 576px) {
  .referHeading span {
    font-size: 24px;
  }

  .referCOntent h1 {
    font-size: 35px;
  }

  .referCOntent p {
    font-size: 16px;
  }

  .cloudsBottom {
    height: 120px;
  }
}
