.backBtttn {
  display: flex;
  margin-top: 1.5rem;
  p {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    margin-left: 5px;
  }
}

/*-------------------Rewards-------------*/
.myRwrds {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  padding: 0rem 1.5rem;
  border: none;
  border-radius: 20px;
  background-image: url("../../Assets/rwrHero.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 170px;

  .lefRwrd {
    width: 70%;
    h1 {
      white-space: nowrap;
      text-align: left;
      font-size: 48px;
      font-weight: 700;
      color: var(--white);
      font-family: "Satoshi";
    }
  }

  .RIghtRwrd {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 120px;
      max-height: 120px;
      object-fit: cover;
    }
  }
}

/*---------------------Sign Up Bonus-----------*/
.sigUpBonus {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  /* align-items: center; */
  margin-top: 1rem;

  .contntSignIn {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
  }
  .leftSIgn {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
    width: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .signData {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
      height: 60px;
      h1 {
        margin: 0;
        font-size: 24px;
        color: var(--white);
        font-weight: 600;
      }

      p {
        color: var(--white);
        margin: 0;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .RIghtSIgn {
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 24px;
      font-weight: 600;
    }
    p {
      margin: 0;
      font-size: 20px;
      font-weight: 400;
      color: var(--text-grey);
    }
  }
}

@media all and (max-width: 768px) {
  .myRwrds {
    padding: 3rem 0rem;
    justify-content: center;

    .RIghtRwrd {
      display: none;
    }
  }

  .myRwrds {
    height: 130px;
    .lefRwrd {
      width: 100%;
      h1 {
        font-size: 36px;
        text-align: center;
      }
    }
  }
}

@media all and (max-width: 576px) {
  .myRwrds {
    padding: 3rem 0rem;
    justify-content: center;

    .RIghtRwrd {
      display: none;
    }
  }

  .myRwrds {
    height: 80px;
    .lefRwrd {
      width: 100%;
      h1 {
        font-size: 24px;
        text-align: center;
      }
    }
  }

  .sigUpBonus {
    .RIghtSIgn {
      h1 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
      }
    }

    .leftSIgn {
      width: 70px;
      min-width: 70px;
      height: 70px;
      border-radius: 18px;
    }
  }
}

.signData h1 {
  margin: 0;
  font-size: 24px;
  color: var(--white);
  font-weight: 600;
}

@media screen and (max-width: 576px) {
  .signData h1 {
    font-size: 16px;
  }
}
