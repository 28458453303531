.AboutHeroSec {
  max-height: 400px;
  padding: 3rem 2rem;
  background-image: url("../../Assets/AboutHeroCover.svg");
  background-size: cover;
  background-position: center;

  p {
    color: var(--white);
    font-size: 24px;
    text-align: left;
    margin: 0;
    font-weight: 400;
  }
  img {
    height: 45px;
    width: 45px;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 576px) {
  .AboutHeroSec {
  
    p {
      font-size: 16px;
    }
  }
}

.BttmbuttonAbouthero {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  margin-top: 2rem;

  button {
    backdrop-filter: blur(0px) saturate(200%);
    -webkit-backdrop-filter: blur(0px) saturate(200%);
    color: var(--white);
    background-color: #cdcccb5a;
    border-color: transparent;
    padding: 0.3rem 1.1rem;
    font-size: 16px;
    border-radius: 12px;
  }
}

.journeyAboutContainer {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 2rem 0;
  background-color: var(--light-pink-color);
}
.aboutHeading {
  p {
    font-weight: 400;
    color: var(--text-grey);
    margin: 0;
    font-size: 24px;
    line-height: 30px;
  }
  h1 {
    padding-top: 0.3rem;
    font-weight: 600;
    margin: 0;
    font-size: 35px;
    color: var(--black);
  }
}

.aboutProfileCOnt {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-top: 3.5rem;

  gap: 1.5rem;
}
.leftProfile {
  position: relative;
}
.abotutimgright {
  display: flex;
  flex-direction: column;
  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    color: var(--black);
  }
  p {
    font-weight: 400;
    color: var(--black);
    margin: 0;
    font-size: 14px;
  }
}
.aboutProfileaFter,
.aboutProfilebefore {
  position: absolute;
}

.aboutProfilebefore {
  top: 13px;
  left: 7px;
  z-index: 1;
}

.aboutProfileaFter {
  z-index: 2;
}

.leftProfile {
  position: relative;
  height: auto;
  height: 100px;
  width: 100px;
  img {
    height: 100px;
    width: 100px;
  }
}

.aboutBoxProfiletex {
  padding-top: 2rem;
  p {
    color: var(--black);
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
  }
}

.boxcontant,
.truestedpatner {
  padding: 2rem 1rem;

  position: relative;
  overflow: hidden;
  background-color: var(--cream-color);
  border-radius: 12px;

  h1 {
    font-weight: 700;
    margin-top: 2rem;
    font-size: 30px;
    color: var(--black);
  }
  p {
    margin: 0;
    font-size: 20px;
    line-height: 30px;
    color: var(--black);
  }
  img {
    position: absolute;
    top: -55%;
    right: -60px;
  }
}
.truestedpatner {
  padding: 0px;
  background-color: transparent;
  h1 {
    padding-top: 1rem;
    padding-bottom: 0.3rem;
    margin: 0px;
  }
}
.numberCOunt {
  display: flex;
  max-width: 70%;
  border-bottom: 1.4px solid var(--primary-color);
  padding-bottom: 1.4rem;
  h1 {
    padding-left: 10px;
    font-weight: 500;
    font-size: 40px;
  }
}
.ourValueBox {
  margin-top: 1rem;
}
.marginTop {
  margin-top: 3rem;
}
@media screen and (max-width: 600px) {
  .AboutHeroSec {
    max-height: 100%;
  }
}
