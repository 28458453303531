.heroPrivacy {
  background: linear-gradient(90.27deg, #fffeff 0%, #ffb3a9 100%);
  padding: 5rem 0;
}

.containersssss {
}
.heroPrivacy h1 {
  font-family: "Satoshi", sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 66.08px;
  letter-spacing: -0.5px;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--black);
}

@media screen and (max-width: 576px) {
  .heroPrivacy h1 {
    font-size: 40px;
  }
  .heroPrivacy p {
    font-size: 16px;
    max-width: 98% !important;
  }
}

.heroPrivacy p {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.5px;
  color: #666666;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
  color: var(--black);
}

.privacy-policy-link {
  margin: 3rem 0;
  padding: 0.5rem 1rem;

  color: #000000;
  background-color: rgba(247, 248, 249, 1);
}
.privacy-policy-link1 {
  font-size: 20px;
  margin: 3rem;
  padding: 0.5rem 1rem;

  color: #666666;
}

.profile-section h2 {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.32px;
  letter-spacing: -0.5px;
  text-align: left;
}
.pppp {
  font-weight: 700;
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .heroPrivacy {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}