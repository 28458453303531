@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "Satoshi";
  src: url("./Fonts/OTF/Satoshi-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.santosiFOntCLass {
  font-family: "Satoshi";
}
.fontSizeCLass {
  font-size: 16px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-colorHover: #f7503a;
  --primary-color: #f86855;
  --light-orange: #feedeb;
  --light-bg: #f7f8f9;
  --black: #181818;
  --text-black: #181818;
  --light-borwn: #4c4c51;
  --Light-black: #4c4c51;
  --white: #fff;
  --text-grey: #666666;
  --text-grey_dark: #999999;
  --light-grey: #cbc7c2;
  --dark-green: #008000;
  --Hovergreen: rgb(38, 126, 61);
  --cream-color: #f7f8f9;
  --light-pink-color: #fef0ee;
  --blue-color: #0672cb;
  --blue-color-hover: #0979d5;
  --blue-color-hover: #0467b7;
  --blue-text: #0672cb;
  --purpl-text: #8c3ef1;
  --border-line-hotel: #e1e1e1;
  --box-shadow: 0px 10px 30px 0px #0000000d;
  --dark-purpule: #94087a;
  --Pink: #ffcdf6;
  --light-orenge: #fff4f3;
  --light-blue: #f2f3f7;
  --gren-light: #a3c853;
  --yellow: #fff833;
  --dark-yellow: #ecb907;
  --red: #e93f33;
  --light-red: #f63a2e;
  --red-bg: rgb(246, 58, 46, 4%);
  --dark-red: #af2d24;
  --border-color: #edeeef;
  --light-green: #38c172;
  --dark-green: #0db85c;
  --dark-green-hover: #04a04d;
  --gray-faded: #a5a5a8;
  --primary-gradient-bg: radial-gradient(
    109.07% 511.83% at 98.93% 3.03%,
    #ff806f 0%,
    #e25f4d 100%
  );

  --pink-gradient-bg: linear-gradient(
    90deg,
    #b8cbb8 0%,
    #b8cbb8 0%,
    #b465da 0%,
    #cf6cc9 33%,
    #ee609c 66%,
    #ee609c 100%
  );
  --blue-gradient: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);
}

.App {
  font-family: "Inter", sans-serif;
}
a {
  text-decoration: none;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.ellipsis {
  white-space: initial;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--black);
}

.ellipsis2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  height: 2.6em;
  line-height: 1.3em;
}

.hidden {
  display: none !important;
}
/* ::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollr-gray) !important;
  border-radius: 6px !important;
} */
/* Initially, hide the marquee content */
/* Hide marquee on larger screens */
.marquee-content {
  overflow: hidden;
  white-space: nowrap;
}

.marquee-wrapper {
  display: flex;
  animation: scroll 15s linear infinite;
}

.marquee-items {
  display: flex;
  gap: 16px; /* Space between items */
}

@keyframes scroll {
  from {
    transform: translateX(100%); /* Start off-screen to the right */
  }
  to {
    transform: translateX(-100%); /* Move off-screen to the left */
  }
}

@media (max-width: 991px) {
  .marquee-content {
    display: block;
  }
  .normal-content {
    display: none;
  }
}

@media (min-width: 992px) {
  .normal-content {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .marquee-content {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .collections_sections_heading h3 {
    font-size: 23.39px;
    font-weight: 600;
    line-height: 28.07px;
  }
  .collections_sections_heading h6 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .popularVenues_heading_container h3 {
    font-size: 23.39px;
    font-weight: 600;
    line-height: 28.07px;
  }
  .city_description2 h6 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
  .pagination_controls a {
    font-size: 12px;
    font-weight: 400;
    line-height: 28.07px;
  }
  /* .black_section_carousel h6 {
    font-size: 30px;
    font-weight: 400;
    line-height: 38.11px;
  } */
  .black_section_carousel p {
    font-size: 16.78px;
    font-weight: 400;
    line-height: 28.77px;
  }
  .linear_progressBar_section {
    padding-left: 05px;
  }
}

.primaryBtn {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 32px;
  font-size: 16px;
  border: none;
  color: var(--white);
  font-weight: 500;
  height: 56px;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
}

.primaryBtn:hover {
  background-color: var(--primary-colorHover);
}

.secondaryBtn {
  background-color: var(--white);
  border-radius: 32px;
  font-size: 16px;
  border: none;
  color: var(--Light-black);
  border: 1px solid var(--Light-black);
  font-weight: 500;
  height: 56px;
  padding: 0.5rem 1rem;
  width: 100%;
}

.secondaryBtn:hover {
  background-color: var(--border-color);
}

.DarkButtonClass {
  font-size: 16px;
  font-weight: 500;
  border: none;
  color: var(--white);
  background-color: var(--Light-black);
  border: 1px solid var(--Light-black);
  padding: 0.5rem 1rem;
  border-radius: 50px;
  width: 100%;
  height: 56px;
  &:hover {
    background-color: var(--text-black);
  }
}

.marginTOpCLass {
  margin-top: 3rem;
}

.marginTOpCLass2 {
  margin-top: 2rem;
}

@media screen and (min-width: 768px) {
  .paddinLeftAfter768 {
    padding-left: 0;
  }

  .paddinRightAfter768 {
    padding-right: 0;
  }
}

.headingh5 {
  font-weight: 600;
  color: var(--text-black);
  margin-bottom: 0.7rem;
}

.separator {
  width: 100%;
  height: 1px;
  border-top: 1px dashed var(--border-color);
  border-width: 2px;
  margin: 1rem 0;
}

.saperatorVertical {
  height: 100%;
  width: 1px;
  border-left: 1px solid var(--text-grey);
}

.roundsGreyLeft {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: var(--light-bg);
  border: 1px solid #e1e1e1;
  position: absolute;
  left: -16px;
  top: 43%;
  z-index: 2;
}

.roundsGreyRight {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: var(--light-bg);
  border: 1px solid #e1e1e1;
  position: absolute;
  right: -16px;
  top: 43%;
  z-index: 2;
}

.roundsGreyLeft1 {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: var(--light-bg);
  position: absolute;
  left: -20px;
  top: 43%;
  z-index: 2;
}

.roundsGreyRight1 {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: var(--light-bg);
  position: absolute;
  right: -20px;
  top: 43%;
  z-index: 2;
}

.padding0 {
  padding: 0;
}

.subHeadStick {
  background-color: var(--white);
  position: sticky;
  top: 79px;
  z-index: 10;
  padding-top: 30px;
}

.threelineElepsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; /* Limits the text to 3 lines */
  line-clamp: 3; /* Limits the text to 3 lines */
  line-height: 1.5; /* Adjust the line height as needed */
  max-height: calc(
    1.5em * 3
  ); /* Match the line-height times the number of lines */
}
