/*------------------filter section-----------*/
.mapBg {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid var(--border-line-hotel);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  background-image: url("../../Assets/mapBg.svg");

  min-height: 90px;
  padding: 1rem 1.5rem;
  button {
    /* position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%); */
    height: fit-content;
    white-space: nowrap;
    font-weight: 400;

    font-size: 16px;
    color: var(--white);
    border: none;
    border-radius: 8px;
    padding: 0.6rem 1rem;
    background-color: var(--blue-color);
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: var(--blue-color-hover);
    }
  }
}
.filterLeftSection {
  height: 100vh;
  overflow-y: auto;
  padding: 1rem;
  padding-left: 0;
}

.filterLeftSection::-webkit-scrollbar {
  width: 7px;
  height: 3px;
}

.filterLeftSection::-webkit-scrollbar-thumb {
  background-color: white !important;
  border-radius: 6px !important;
}

.filterLeftSection::-webkit-scrollbar-track {
  background: transparent;
}
.HotlLIstFilterDiv {
  overflow: hidden;
  margin-top: 1rem;

  .hotelRightSection {
    height: 100vh;
    overflow-y: scroll;
    padding: 1rem 0;
    scrollbar-width: thin;
    scrollbar-color: white transparent;
  }

  .hotelRightSection::-webkit-scrollbar {
    width: 7px;
    height: 3px;
  }

  .hotelRightSection::-webkit-scrollbar-thumb {
    background-color: white !important;
    border-radius: 6px !important;
  }

  .hotelRightSection::-webkit-scrollbar-track {
    background: transparent;
  }
}

.locationHotelNameHead {
  display: flex;
  align-items: center;
  img {
    height: 15px;
    width: 15px;
    margin-right: 6px;
  }
  h1 {
    font-weight: 600;
    margin: 0;
    font-size: 24px;
  }
}

/*-----------------------------Leftt SIde hotel Data-------------------*/
.locatiinDta,
.graySclllDIv {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--border-line-hotel);

  overflow: hidden;
}
.locatiinDta,
.soldOutDiv {
  position: relative;
  margin-top: 2rem;
}

.hottleLeftDDtaa {
  height: 100%;
  position: relative;
  .hotlimglef {
    width: 100%;
    height: 100%;
    max-height: 240px;
    object-fit: cover;
    border-top-left-radius: 12px;
  }

  .newLounchDIv,
  .Trading,
  .SoldOut {
    display: flex;
    gap: 5px;
    position: absolute;
    top: 1rem;
    left: 0.5rem;
    img {
      height: 15px;
      width: 15px;
    }
    p {
      padding: 0.4rem 0.6rem;
      border-radius: 6px;
      color: var(--white);

      font-weight: 600;
      margin: 0px;
      cursor: text;
    }
  }

  .newLounchDIv {
    p {
      background: var(--pink-gradient-bg);
    }
  }
  .Trading {
    p {
      background: var(--blue-gradient);
    }
  }
}

.HEartDivAbs {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 2px;
  cursor: pointer;
  border-radius: 23px;
  background-color: var(--white);
  img {
    height: 23px;
    width: 27px;
  }
}

.soldoutdiv {
  position: relative;
  filter: grayscale(0%);
}

.graySclllDIv {
  /* opacity: 0.6; */

  .topDivHotelContrr,
  .loctionBtmData {
    opacity: 0.5;
  }

  .hottleLeftDDtaa {
    img {
      filter: grayscale(100%);
      transition: filter 0.3s ease;
    }
  }
}

.SoldOut {
  position: absolute;
  z-index: 3;
  left: 1rem;

  top: 1rem;
  p {
    padding: 0.4rem 0.6rem;
    border-radius: 6px;
    color: var(--white);

    font-weight: 600;
    margin: p;
    cursor: text;
    max-width: 90px;
    background-color: var(--dark-yellow);
  }
}

/*---------------------------hotel Data Map top COntetn------------------*/

.cardStyle {
  border: 1px solid var(--border-line-hotel);
  border-radius: 12px;
}

.leftCOntnetTOp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  .lefUnderData,
  .lefRightData {
    display: flex;
    align-items: center;
  }
  .vertical-line {
    width: 1px;
    height: 24px;
    background-color: var(--border-line-hotel);
    margin: 0 10px;
  }
  .onprimeLoc,
  .aqlImgDIv {
    display: flex;
    align-items: center;

    p {
      align-items: center;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: var(--text-grey);
    }
  }

  .onprimeLoc {
    img {
      height: 15px;
      width: 15px;
      margin-right: 6px;
    }
  }
  .aqlImgDIv {
    img {
      height: 10px;
      width: 10px;
      margin-right: 6px;
    }
  }

  .rttingCOntrDiv {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .lefRightDataRtting {
      display: flex;
      align-items: center;
      height: 32px;
      gap: 0.5rem;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid var(--border-line-hotel);
      box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-025)
        var(--sds-size-depth-100) var(--sds-size-depth-0)
        var(--sds-color-black-100);

      .ratting {
        display: flex;
        padding: 0.5rem;
        align-items: center;

        background-color: var(--light-orange);
        color: var(--primary-color);

        span {
          font-size: 12px;
          font-weight: 500;
        }
      }

      .ratting2,
      .ratting3 {
        span {
          font-weight: 400;
          font-size: 12px;
          color: var(--black);
        }
      }

      .ratting3 {
        padding-right: 7px;
      }
    }
    img {
      box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-025)
        var(--sds-size-depth-100) var(--sds-size-depth-0)
        var(--sds-color-black-100);

      padding: 8px;
      border-radius: 13px;
      border: 1px solid var(--border-line-hotel);
      height: 32px;
      width: 32px;
    }
  }
}
.middleDataHotelCOntnr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 0.4rem; */
  .hottlMiddleData {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;

    h1 {
      margin: 0;
      font-weight: 600;
      font-size: 22px;
      color: var(--black);
    }
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: var(--text-grey);
    }
  }
}

.lastTopContr {
  .hottlMiddleData2 {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h2 {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
    }
    .LastopData {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.7rem;

      p {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        color: var(--text-grey);
      }
      span {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: var(--text-grey);
      }

      .popList {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
.topDivHotelContrr {
  height: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  padding: 1rem;
}
/*---------------------------hotel Data Map Botttm COntetn------------------*/

.loctionBtmData {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  background-color: var(--light-bg);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  .leftData {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    li {
      white-space: nowrap;
      list-style: none;
      font-weight: 400;
      font-size: 13px;
      color: var(--blue-text);
    }
  }

  /*------------------------------RIght SIde hotel Data-------------------*/
}

.loctionBtmData .leftData {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.rightSIdeData .RIghtData {
  display: flex;
  flex-direction: column;
}

.rightSIdeData .bokkNowwBtn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.rightSIdeData .underataRight {
  display: flex;
}

.rightSIdeData {
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  .RIghtData {
    display: flex;
    flex-direction: column;
    p {
      font-size: 10px;
      font-weight: 400;
      margin: 0;
    }
    .underataRight {
      display: flex;
      gap: 0.6rem;
      align-items: center;
      p {
        margin: 0;
        padding: 0.3rem 0.5rem;
        border-radius: 20px;
        background-color: var(--light-orange);
        color: var(--primary-color);
        font-size: 10px;
      }
      h2 {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        color: var(--text-grey);
        text-decoration: line-through;
      }
    }
  }

  .bokkNowwBtn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    h1 {
      padding-left: 5px;
      margin: 0;
      font-weight: 600;
      font-size: 32px;
    }

    button {
      border: none;
      white-space: nowrap;
      background-color: var(--primary-color);
      font-size: 14px;
      border-radius: 50px;
      padding: 0.5rem 1rem;
      color: var(--white);

      &:hover {
        background-color: var(--primary-colorHover);
      }
    }
  }
}
/*--------------------QR DIV--------------------*/

.QrHotlDiv {
  margin-top: 2rem;
}

/*----------------CHat Us Buttton----------*/

.chAtUsimg {
  cursor: pointer;
  position: fixed;
  z-index: 3;
  bottom: 20%;
  right: 2rem;
  width: 160px;
  height: 50px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
}

.chAtUsimg img {
  width: 100%;
  height: 100%;
}

.chAtUsimg:hover {
  transform: scale(1.1);
}

@media all and (max-width: 768px) {
  .filterLeftSection {
    height: auto;
  }
  .eclipseText {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  .ratting2 {
    display: none;
  }

  .popList {
    display: none;
  }
  .popList:nth-child(-n + 3) {
    display: flex;
  }
}

.dot {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin: 0 2px; /* Adjusts spacing around the dot */
}

@media screen and (max-width: 991px) {
  .filterSection991px {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content !important;
    padding-top: 0;
    gap: 1rem;
  }

  .mapBg {
    margin-bottom: 0;
    width: 100%;
    min-height: 50px;
    padding: 0.6rem 1rem;
  }

  .mapBg button {
    font-size: 14px;
    height: 35px;
    padding: 0 1rem;
  }
}

.filterIconButton {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  .hotlimglef {
    border-top-right-radius: 12px;
  }
}

.starrrIng {
  border: none !important;
  width: 12px !important;
  height: 12px !important;
  padding: 0 !important;
  margin-right: 3px !important;
}