
/* 
.guestword  {
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.32px;
    letter-spacing: 0;
    text-align: left;
    margin: 0; 
} */
.GuesHedddd{
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    p{
        margin: 0;
    }
}
.guestsectionGuesPol {
    margin-top: 1rem;
    h1{
        font-size: 20px;
    }
    li{
        margin-left: 10px;
        font-weight: 400;
        color: var(--text-grey);
        font-size: 14px;
    }
}