.FontSizeCLass {
  font-family: "inter";
}

.OffrHeroSection {
  margin-top: 3rem;
}
.offerTOpHead {
  font-size: 24px;
  font-weight: 700;
}
.heroUnderDataOfffer {
  background-color: var(--light-bg);
  border-radius: 12px;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;

  padding: 1.4rem 1rem;

  p {
    padding-top: 1rem;
    font-weight: 600;
    font-size: 19px;
    color: var(--black);
  }
  label {
    font-weight: 400;
    font-size: 15px;
    color: var(--text-grey);
  }
}

.offerWelcomeHead {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  h1 {
    font-weight: 600;
    font-size: 25px;
    color: var(--primary-color);
  }
  button {
    width: fit-content;
    height: fit-content;
    padding: 0.5rem 0.9rem;
    font-size: 13px;
  }
}

@media screen and (max-width: 389px) {
  .offerWelcomeHead {
    gap: 0;
  }
}

/*-----------------Offer data-------------*/
