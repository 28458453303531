@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.hotelDetailHeroCont {
  overflow: hidden;
  margin-top: 1rem;
  font-family: "Inter", sans-serif;
}
.borderClr {
  position: relative;
  height: auto;
  /* overflow: hidden; */
  border-radius: 12px;
  padding: 1rem;
  border: 1px solid var(--border-line-hotel);
}
.locationSTatus {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  button {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    cursor: text;
    font-size: 14px;
    background-color: var(--white);
    border-radius: 12px;
    padding: 0.5rem 1rem;
    color: var(--text-grey);
    border: 1px solid #edeeef;
  }
}

.locationDetl {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.lefDetl {
  h2 {
    font-size: 20px;
    font-weight: 700;
  }
  p {
    color: var(--text-grey);
    font-weight: 400;
    font-size: 14px;
  }
}

.right {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  img {
    height: 20px;
    width: 20px;
  }
}

.revieWText {
  display: flex;
  gap: 5px;
  align-items: flex-end;
  h1 {
    margin: 0;
    font-size: 36px;
    color: var(--black);
  }
  span {
    margin: 0;
    font-size: 16px;
    color: var(--blue-color);
  }
}

.verticalLine {
  width: 1px;
  height: 36px;
  background-color: #999999;
  margin: 0 10px;
}

/*---------------RIght hero design -----------*/

.hotel-booking-card {
  padding: 1.5rem;
  border-radius: 0.75rem;
  background: white;
  border: 1px solid var(--border-line-hotel);
}

.card-header {
  margin-bottom: 1.5rem;
}
.hotel-info {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.hotel-info h2 {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
}

.badge-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.icon {
  width: 1rem;
  height: 1rem;
  color: var(--dark-purpule);
}

.couple-badge {
  padding: 0.25rem 0.75rem;
  background-color: var(--Pink);
  color: var(--dark-purpule);
  border-radius: 6px;
  font-size: 0.875rem;

  img {
    margin-right: 8px;
  }
}
.family-badge {
  padding: 0.25rem 0.75rem;
  background-color: "#E5E2FF";
  color: "#180894";
  border-radius: 6px;
  font-size: 0.875rem;

  img {
    margin-right: 8px;
  }
}
.corporate-badge {
  padding: 0.25rem 0.75rem;
  background-color: "#FFEAC8";
  color: "#905C07";
  border-radius: 6px;
  font-size: 0.875rem;

  img {
    margin-right: 8px;
  }
}

.price-section {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.discount-badge {
  padding: 0.25rem 0.5rem;
  background-color: var(--light-orange);
  color: var(--primary-color);
  border-radius: 0.25rem;
  font-size: 12px;
}

.original-price {
  color: #9ca3af;
  text-decoration: line-through;
}

.current-price {
  font-size: 1.5rem;
  font-weight: 700;
}
.dateCOntrrr {
  padding: 0 1rem;
  border: 1px solid var(--border-line-hotel);
  border-radius: 12px;
  margin-bottom: 1rem;
}
.date-section {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}
/* .date-section.with-border {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-line-hotel);
} */

.nightsNumberLineDetaIL {
  border-color: var(--border-line-hotel) !important;
  position: absolute;
  z-index: 0;
}

.nightN9Detaial {
  position: absolute;
  z-index: 0;
  background-color: #6a6a6f !important;
  border-radius: 6px;
}

.nightN9Detaial p {
  margin: 0;
  color: var(--white);
  padding: 0.2rem 0.5rem;
}

.Detltitile {
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.fontWEight {
  color: var(--black);
  font-weight: 600;
}
.dateTxt {
  font-size: 16px;
  color: var(--text-grey);
  font-weight: 400;
  margin: 0;
}

.details-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.roomSHowcontainer {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.roomDrodown,
.roomDrodownHome,
.catgryDropDwnn,
.roomDrodownHeader {
  position: absolute;
  min-width: 300px;

  overflow: auto;
  z-index: 2;
  background-color: var(--white);

  display: none;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.3s ease, opacity 0.3s ease;
  border-radius: 12px;
  margin-top: 1rem;
  border: 1px solid var(--border-line-hotel);
  padding: 1rem;

  .rightROoomtCOnt {
    display: flex;
    justify-content: center;
    width: 100px;
  }
  label {
    width: 20px;
    text-align: center;
  }
}
.roomDrodownHome {
  max-height: 400px;
  overflow: auto;
  top: 10rem;
  left: 26%;
}
.roomDrodownHeader {
  max-height: 400px;
  max-width: 300px;
  overflow: auto;
  top: 2.5rem;
  left: -1rem;
}
.roomDrodown {
  bottom: -13.5rem;
}

.catgryDropDwnn {
  bottom: -7.5rem;
}
.roomDrodown.open,
.roomDrodownHome.open,
.roomDrodownHeader.open,
.catgryDropDwnn.open {
  display: block;
  height: auto;
  opacity: 1;
}
.roomDrodown.open {
  max-height: 372px;
  overflow: auto;
}

.dropDOwnArrowDOwn {
  img {
    height: 21px;
    width: 21px;
  }
}

.check-in,
.check-out {
  cursor: pointer;
  padding: 1rem 0;
}

.detail-row {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-line-hotel);
  border-radius: 12px;
  position: relative;
}

.sub-text {
  color: var(--text-grey);
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.chevron {
  width: 1.25rem;
  height: 1.25rem;
  stroke: currentColor;
  stroke-width: 2;
  fill: none;
  color: #9ca3af;
}

.book-button {
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--primary-colorHover);
  }
}
.discbriBaar {
  z-index: 3;
  background-color: var(--white);
  position: fixed;
  top: 89px;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  width: 100%;
  font-size: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: none;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.discbriBaar.show {
  display: block;
}
.BarMrginActiv {
  margin-top: 7rem !important;
}

.discribtCOntList {
  overflow: auto;
  z-index: 3;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1.5rem;
  color: var(--Light-black);
  transition: all 0.3s ease;

  p {
    white-space: nowrap;
    padding-bottom: 0.5rem;
    cursor: pointer;
    margin: 0;
    font-weight: 400;
    transition: all 0.3s ease;
    &:hover {
      color: var(--primary-color);
    }
    &.active {
      color: var(--primary-color);
    }
  }
}

.fixDivClass {
  width: 41%;
  top: 150px;
  position: fixed;
}

.bookingSCreenShowMob {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: fixed;
  width: 100%;
  justify-content: center;
  padding: 0.7rem;
  z-index: 6;
  display: none;
  bottom: 1px;
  backdrop-filter: blur(3px);
  &.active {
    opacity: 1;
    transform: translateY(0);
  }

  button {
    width: 95%;
    border-radius: 0.5rem;
  }
}
/* Add some responsive adjustments */
@media (max-width: 999px) {
  .fixDivClass {
    width: auto;
    position: relative;
  }
  .bookingSCreenShowMob {
    display: flex;
  }

  .BarMrginActiv {
    margin-top: 9rem !important;
  }
}
@media (max-width: 651px) {
  .locationDetl {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 640px) {
  .hotel-booking-card {
    padding: 1rem;
  }

  .date-section {
    gap: 0.5rem;
  }
}
.heart-container {
  cursor: pointer;
  height: "36px";
  width: "36px";
  display: "flex";
  align-items: "center";
  justify-content: "center";

  img {
    &:hover {
      transform: scale(1);
    }
  }
}

.heart-icon {
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: scale(1);
  opacity: 1;
}

.heart-icon.filled {
  transform: scale(1.1);
  opacity: 0.8;
}

/*------------------ Gallery container ------------*/
.lefHotelDetailimgs {
  border-radius: 12px;
  overflow: hidden;
  background-color: var(--light-blue);
  padding: 1rem;
}

/* Gallery grid */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 8px;
}

/* Main large image */
.main-image {
  grid-column: span 6;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  max-height: 400px;
}

/* Right column */
.right-column {
  grid-column: span 6;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
}

/* Image containers */

.hotelDetailHeroCont {
  .image-container {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    max-height: 200px;
  }

  /* Image styling */
  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
}

.gallery-image:hover {
  transform: scale(1.05);
}
.overlayHotel {
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1));
}

/* View all button */
.view-all-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--primary-color);
  color: var(--white);
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  backdrop-filter: blur(4px);
  transition: background-color 0.2s ease;
  font-size: 15px;
}

.view-all-btn:hover {
  background: var(--primary-colorHover);
}

/* Hotel details section */
.hotel-details {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

.details-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.details-section h2 {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
}

.details-section ul {
  list-style: disc inside;
  color: #666;
}

.details-section li {
  margin: 8px 0;
}

/*-------------------Discribtion------------*/
.DiscribtionCOnt {
  margin-top: 3rem;
  h1 {
    font-size: 20px;
    color: var(--black);
    font-weight: 700;
  }
  p {
    padding-top: 1rem;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: var(--text-grey);
    padding-bottom: 2rem;

    border-bottom: 1px solid var(--border-line-hotel);
  }
}

/*------------popular facilitiess----------*/

.pupulrFecHeading {
  margin-top: 3rem;
  h1 {
    font-weight: 700;
    font-size: 20px;
    color: var(--black);
  }
}
.PopulerFectilitie {
  padding-top: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}

.PupllrText {
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  gap: 5px;
}
.PupllrText img {
  width: 24px;
  height: 24px;
}
.catSections img {
  width: 200px;
  border-radius: 10px;
}

.PupllrText:nth-child(3n + 1) {
  justify-self: start;
}

.PupllrText:nth-child(3n + 2) {
  justify-self: center;
}

.PupllrText:nth-child(3n + 3) {
  justify-self: end;
}

.PopulerFectilitie span {
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: var(--text-grey);
}

.sHO_ALLL {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 14px;
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid var(--black);
}

@media (max-width: 555px) {
  .PopulerFectilitie {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 400px) {
  .PopulerFectilitie {
    grid-template-columns: 1fr;
  }
}

/*-------------primium ac room --------------*/

.CatagoriDIv {
  margin-top: 2.5rem;
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid var(--primary-color);
  position: relative;
}

.premiumAcRoom,
.Luxuryroom {
  position: absolute;
  top: -1.3rem;
  left: 2.8%;

  border-radius: 12px;
  width: fit-content;
  padding: 0.5rem 1rem;
  display: flex;
  background-color: var(--primary-color);
  p {
    color: var(--white);
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
}
.Luxuryroom {
  border: 1px solid var(--border-line-hotel);
  background-color: var(--white);
  p {
    color: var(--text-grey);
  }
}

.catSections {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: var(--black);
  }
  P {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: var(--text-grey);
  }
}
.LuxryCont {
  margin-top: 2rem;
  hr {
    border: 1px solid var(--border-line-hotel);
  }
}

/*---------Map-------*/
.gogleMap {
  iframe {
    border-radius: 12px;
  }
}
.TravllButtons {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  button {
    color: var(--text-grey);
    border: transparent;
    background-color: transparent;
    border-radius: 20px;
    padding: 0.4rem 1rem;
    font-size: 15px;
    font-weight: 400;

    &:hover {
      /* color: var(--black); */
      background-color: var(--light-blue);
    }
    &.active {
      background-color: var(--light-blue);
    }
  }

  img {
    height: 13px;
    width: 13px;
    margin-right: 5px;
  }
}

.travellText {
  display: flex;
  cursor: pointer;
  margin-top: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem;

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: var(--text-grey);
  }
  h4 {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    color: var(--black);
  }
  span {
    margin-right: 0.5rem;
  }
}

.travellText.active {
  background-color: var(--light-blue);
}

.geetBhwn {
  padding: 0.3rem;
  background-color: var(--light-blue);
}

/*-----------Catagory 2 list--------*/

.catagryList {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  gap: 2.2rem;

  span {
    padding: 0.6rem;
    border-radius: 100%;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
  }
}

.catagryList div {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.circ1 span {
  background-color: var(--dark-green);
}
.circ2 span {
  background-color: var(--gren-light);
}
.circ3 span {
  background-color: var(--primary-color);
}
.circ4 span {
  background-color: var(--yellow);
}
.circ5 span {
  background-color: var(--red);
}
.circ6 span {
  background-color: var(--dark-red);
}

.imgShowModal {
  .roWHtlImgs {
    cursor: pointer;
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 12px;
    overflow: hidden;
    padding: 0.4rem;

    margin: 0.1rem;
    background-color: transparent;
    transition: transform 0.5s ease, box-shadow 0.5s ease !important;

    &:hover {
      transform: translateY(-5px);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
  }
  .modal-header {
    padding: 0.5rem 1rem;
    h1 {
      margin: 0;
      font-weight: 500;

      font-size: 18px;
    }

    button {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      font-size: 14px;

      img {
        margin-right: 5px;
        height: 17px;
        width: 17px;
      }
    }
  }

  .modal-header .btn-close {
    font-size: 12px;
  }

  .carousel slide {
    overflow: hidden;
  }
  .carousel-indicators {
    display: none;
  }
}

.prevCarauselImgSHow {
  height: 100%;
  max-width: 300px;
  border-radius: 30px;
  overflow: hidden;
}

.AFterCLickddata {
  height: 300px;
  width: 300px;
}

.clickdImgHotel {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}
.prevCarauselImgSHow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prevBtn,
.nextBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.prevBtn {
  left: 10px;
  img {
    height: 20px;
    width: 13px;
  }
}

.nextBtn {
  right: 10px;
  img {
    height: 20px;
    width: 13px;
  }
}

.prevBtn:disabled,
.nextBtn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn-icon {
  width: 30px;
  height: 30px;
}

/*------------Additional Information---------------*/

.AdditionlData {
  padding-top: 0.8rem;
  li {
    line-height: 2.5rem;
    font-weight: 400;
    font-size: 16px;
    color: var(--text-grey);
  }
}
@media (max-width: 768px) {
  .gogleMap iframe {
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 480px) {
  .gogleMap iframe {
    width: 100%;
    height: 250px;
  }
}
@media all and (max-width: 596px) {
  .checkInCalanderHotelDEtail {
    flex-direction: column;
    left: 1rem;
    bottom: -34.6rem;
  }
}

/*---------Facilitiee data hw -----------*/

@media all and (max-width: 480px) {
  .catSections {
    margin-top: 2rem;
    flex-direction: column-reverse;
  }

  .lefSection {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    margin-top: 1.2rem;
    justify-content: space-between;
  }
  .price-section {
    margin-top: 0rem;
    gap: 0.2rem;
    flex-direction: column;
    align-items: start;
  }

  .catSections img {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  /* Main large image */
  .main-image {
    grid-column: span 6;
    height: 100%;
    max-height: 400px;
  }

  /* Right column */
  .right-column {
    grid-column: span 6;
    max-height: 400px;
  }

  .image-container {
    width: 100%;
    height: 100%;
  }

  .gallery-grid {
    max-height: 400px;
  }
}
@media screen and (min-width: 577px) and (max-width: 992px) {
  .roomDrodownHome {
    width: auto;
    top: 7rem;
    right: 0rem;
  }
}
@media screen and (max-width: 577px) {
  .roomDrodownHome {
    top: -24rem;
    left: 0;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .main-image .view-all-btn {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  /* Main large image */
  .main-image {
    grid-column: span 12;
  }

  /* Right column */
  .right-column {
    display: none;
  }

  .image-container {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .locationSTatus button {
    font-size: 12px;
    padding: 0.5rem;
    gap: 0.2rem !important;
  }

  .locationSTatus button img {
    width: 10px;
    height: 10px;
  }

  .locationSTatus {
    gap: 0.2rem;
    flex-wrap: nowrap;
  }

  .borderClr {
    padding: 1rem 0.5rem;
  }
}

@media screen and (max-width: 380px) {
  .locationSTatus button {
    font-size: 10px;
  }
}

@media screen and (max-width: 375px) {
  .imgShowModal {
    .roWHtlImgs {
      width: 80px;
      height: 80px;
    }
  }
}

.flowPAth {
  padding-bottom: 1rem;
}

.flowPAth a {
  text-decoration: none;
  color: var(--black);
  font-size: 14px;
}

.flowPAth span img {
  /* width: 14px; */
  height: 16px;
  margin: 0 0.6rem;
}
