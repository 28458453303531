.booking-card {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
}

.hotel-image {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  margin-right: 15px;
  object-fit: cover;
}

.booking-info {
  flex: 1;
}

.booking-info h3 {
  margin: 5px 0;
  font-size: 24px;
  color: var(--text-black);
  font-weight: 600;
}

.booking-info p {
  margin: 2px 0;
  font-size: 16px;
  color: var(--text-grey);
}

.share-button {
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  color: #888;
}
.action-button {
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
  color: #888;
  display: flex;
  align-items: center;
  gap: 5px;
}

@media screen and (max-width: 600px) {
  .hotel-image {
    width: 80px;
    height: 80px;
  }

  .booking-info p {
    font-size: 14px;
  }

  .booking-info p {
    font-size: 12px;
  }

  .booking-info h3 {
    font-size: 18px;
  }
}

/* @media screen and (max-width: 576px) {
  .elepsisClass {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 250px;
  }

  .elepsisClassP {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 220px;
  }
} */

@media screen and (max-width: 425px) {
  .elepsisClass {
    width: 200px;
  }

  .elepsisClassP {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 180px;
  }
}

@media screen and (max-width: 390px) {
  .elepsisClassPTop {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 180px;
  }
}

@media screen and (max-width: 360px) {
  .hotel-image {
    width: 70px;
    height: 70px;
  }

  .booking-info p {
    font-size: 13px;
  }

  .booking-info p {
    font-size: 10px;
  }

  .booking-info h3 {
    font-size: 16px;
  }

  .elepsisClass {
    width: 180px;
  }
}
