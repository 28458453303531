.containerBookingLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;

  background-color: #00000062;
  backdrop-filter: blur(1px);
  z-index: 99999;
}

.loader {
  display: grid;
  grid-template-columns: repeat(2, 50px);
  grid-template-rows: repeat(2, 50px);
  gap: 0px;
  perspective: 1000px;
}

.cube {
  width: 35px;
  height: 35px;
  background-color: var(--primary-color);
  position: relative;
  transform-style: preserve-3d;
  animation: rotate 1.8s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

@keyframes rotate {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

.cube:nth-child(1) {
  animation-delay: 0s;
}
.cube:nth-child(2) {
  animation-delay: 0.2s;
}
.cube:nth-child(3) {
  animation-delay: 0.4s;
}
.cube:nth-child(4) {
  animation-delay: 0.6s;
}
