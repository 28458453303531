.booking-details {
  padding-bottom: 30px 0;
  background-color: var(--white);
}

.subHeadStick h2 {
  font-size: 24px;
  font-weight: bold;
}

.tabs {
  width: -webkit-fill-available;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: auto;
}

.tab {
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: gray;
  border-bottom: 2px solid transparent;
}

.tab.active {
  color: var(--primary-color);
  border-bottom-color: var(--primary-color) !important;
}
.pagination-container {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0px 30px;
  background-color: var(--white);
}

.pagination-line {
  width: 100%;
  border: 1px solid #ccc;
}

.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.pagination-arrow {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.pagination-text {
  font-size: 16px;
  color: #666;
}
.FavrateDivs {
  margin-bottom: 3rem;
}
.SubmtFeedBck {
  height: fit-content;
}
