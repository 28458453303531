.career-page {
  /* font-family: Arial, sans-serif; */
  padding: 0px;
}

.join-team {
  height: 361px;
  background: linear-gradient(to right, #fffeff, #ffdbd8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.join-team h1 {
  font-size: 54px;
  margin: 0;
}

.join-team p {
  color: #666;
  font-size: 20px;
}

.main-Career {
  padding-top: 20px;
}

.about-us {
  margin: 40px 0;
}

.about-us h2 {
  font-size: 24px;
  color: #666;
  font-weight: 400;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 991px) {
  .about-us p {
    font-size: 20px !important;
  }
  .job-listings .toptxt {
    font-size: 16px;
  }
  .pagination {
    /* align-items: center !important; */
    justify-content: center !important;
  }

  .jobtxt {
    font-size: 16px;
  }
}

@media screen and (max-width: 576px) {
  .jobtxt {
    font-size: 14px !important;
  }

  .about-us p {
    padding: 20px !important;
  }
}
@media only screen and (max-width: 450px) {
  .join-team h1 {
    font-size: 40px;
  }

  .join-team p {
    font-size: 14px;
    text-align: center;
  }
  .about-us p {
    font-size: 20px !important;
  }
  .about-us h2 {
    font-size: 24px;
    color: #666;
    font-weight: 400;
  }
  .pagination {
    /* align-items: center !important; */
    justify-content: center !important;
  }
}
.about-us h3 {
  font-size: 35px;
  color: #333;
  font-weight: 700;
  margin-top: 10px;
}

.about-us span {
  color: #ff4a4a;
}

.about-us p {
  margin-top: 40px;
  padding: 20px 50px;
  background-color: #f7f8f9;
  font-size: 24px;
  border-radius: 12px;
  color: #333;
  font-weight: 400;
}

.job-listings {
  margin: 70px 0;
}

.job-listings h4 {
  font-size: 35px;
  color: #000;
  font-weight: 700;
  margin-top: 30px;
}

.job-listings .toptxt {
  font-size: 24px;
  color: #000;
  font-weight: 400;
}

.job-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin: 30px 0;
}

.job-details h3 {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  color: #000;
}
.job-info {
  display: flex;
  gap: 20px; /* Adjust space between each item */
  margin-top: 8px;
  flex-wrap: wrap;
}
.jobtxt {
  font-size: 20px;
  border-radius: 12px;
  color: #333;
  font-weight: 500;
}
.job-details span {
  color: #666;
  font-weight: 500;
}

.apply-button {
  background-color: #4c4c51;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 36px;
  cursor: pointer;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: var(--black);
  }
}

.page-number {
  /* padding: 5px 10px; */
  cursor: pointer;
  color: #333;
  border: 1px solid transparent;
  font-size: 14px;
}

.page-number.active {
  background-color: #000; /* Or any color for active state */
  color: white;
  font-weight: bold;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-arrow {
  padding: 5px;
  cursor: pointer;
}

.ellipsis {
  padding: 5px;
}

.pagination {
  align-items: center !important;
  justify-content: flex-end;
}

/*---------------------AppplY Career Component Styling -----------------------*/
.applyCareer {
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .inputFormCareer {
    label {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    input {
      border: 1px solid var(--border-color);

      &:hover {
        border: 1px solid var(--black);
        box-shadow: none !important;
      }
      &:focus {
        border: 1px solid var(--black);
        box-shadow: none !important;
      }
    }
    textarea {
      border: 1px solid var(--border-color);
      margin-bottom: 1rem;

      &:hover {
        border: 1px solid var(--black);
        box-shadow: none !important;
      }
      &:focus {
        border: 1px solid var(--black);
        box-shadow: none !important;
      }
    }
  }
}
.AppCareerHEad {
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 2rem;
}
.CVuPLOAD {
  border: 3.5px dashed var(--black);
  border-radius: 10px;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.3rem;
  margin-top: 2rem;
}

.profile_picture_brows {
  padding: 0.5rem 1rem;
  width: fit-content;
  height: fit-content;
}
.uploadTextImg {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  img {
    width: 3rem;
  }
  h6 {
    font-size: 15px;
    margin: 0;
  }
  p {
    font-size: 13px;
    margin: 0;
  }
}
.check-box-gap {
  gap: 10px;
}
.FooterCheckBxx {
  padding-top: 10px;
  margin-bottom: 10px;
  text-align: center;

  span {
    padding-left: 5px;
  }
}
.sbmtbtnDiv {
  margin-top: 1rem;
  align-items: center;
  display: flex;

  justify-content: center;
  p {
    margin: 0;
  }
  button {
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;

    gap: 0.5rem;
    display: flex;
  }
}

.captchaText {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  label {
    margin: 0 !important;
  }
  h6 {
    font-weight: 700;
    color: var(--primary-color);
    margin: 0;
  }
}
#captcha {
  margin-top: 0.7rem;
}
