.HeroBanqueet {
  background-image: url("../../Assets/banquetCoverHeroImg.png");
}

.Databanquet {
  border: none;
  margin-bottom: 1rem;
}

.LeftBorderCl {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 1.4rem;
  border-left: 1.5px solid var(--primary-color);
}

.herobottmData {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  width: 100%;
  justify-content: center;
  gap: 1.5rem;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-color: transparent;
  margin-bottom: -0.4rem;
}

.HeroBottmHeroContentBanqueet {
  background-color: transparent !important;
}

.fontSizingBanqueet {
  font-size: 40px !important;
  font-weight: 400 !important;
}
@media all and (max-width: 948px) {
  .herobottmData {
    margin: 0px;
    background-color: transparent;
  }
}

@media screen and (max-width: 576px) {
  .text36 {
    font-size: 36px !important;
  } 
}
