/*------------------------Quick hotel-----------------------*/

.Hotelimgscontainer {
  margin-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: start;
  gap: 1rem;
  overflow: auto;
}

.QuicklyHotl {
  margin-top: 3rem;
  margin-bottom: 3rem;

  p {
    font-weight: 400;
    color: var(--text-grey);
    font-size: 24px;
    margin: 0;
  }

  h2 {
    font-family: "Satoshi";
    font-weight: 700;
    margin: 0;
    color: var(--text-black);
    font-size: 35px;
  }

  span {
    font-weight: 700;
    margin: 0;
    font-size: 35px;
    color: var(--primary-color);
  }
}

.quickHotlsubhading {
  margin-bottom: 2rem;
  gap: 10px;
}

.image-container {
  overflow: hidden;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

@media screen and (max-width: 991px) {
  .image-container {
    overflow: hidden;
    border-radius: 12px;
    width: 170px;
    height: 170px;
    position: relative;
  }

  .Hotelimgscontainer {
    margin-bottom: 3rem;
  }

  .borderBottomLine {
    display: none;
  }
}

.city-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-width: 205px;
  aspect-ratio: 205 / 201;
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: end;
  background: linear-gradient(180deg, transparent, rgba(255, 87, 34, 0.9));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: background 0.3s ease;

  .group:hover & {
    background: linear-gradient(180deg, transparent, rgba(255, 87, 34, 1));
  }
}

.city-name,
.city-count {
  padding-left: 0.6rem;
  position: relative;
  z-index: 2;

  &.city-name {
    font-size: 22px;
  }

  &.city-count {
    font-size: 13px;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color) !important;
  border-radius: 6px !important;
}

@media screen and (max-width: 555px) {
  .QuicklyHotl {
    h2,
    span {
      font-size: 25px;
    }
  }

  .quickHotlsubhading {
    gap: 5px;
  }
}

.borderBottomLine {
  height: 1;
  width: 50%;
  margin: auto;
  border-bottom: 3px solid var(--primary-color);
  margin-bottom: 3rem;
}

@media screen and (max-width: 576px) {
  .QuicklyHotl h2 {
    font-size: 24px !important;
  }

  .QuicklyHotl span {
    font-size: 24px !important;
  }
}
