.fixedDivHeght {
  height: calc(100vh - 170px);
  overflow-y: hidden;
}

.HotelRattingDiv {
  width: 100%;
  justify-content: space-between;
}
.houseICOnImg {
  height: 23px !important;
  width: 23px !important;
}
.MapViwMiddleData {
  h1 {
    font-size: 15px;
    font-weight: 600;
  }

  p {
    font-size: 12px;
    font-weight: 400;
  }
}

.viwMpview {
  padding: 0.5rem;
  padding-top: 0;
  border-radius: 12px;
  height: calc(100vh - 186px);
  overflow-y: scroll;
  background-color: var(--light-bg);
  .locationHotelNameHead {
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: sticky;
    top: 0px;
    background-color: var(--light-bg);
    z-index: 10;
  }
}

.viwMpview::-webkit-scrollbar {
  width: 1px;
}

.topMargnDvvv {
  margin-top: 2rem;
}

.mrginClass {
  margin-top: 1rem;
}

.mapViewHotlDataBttm {
  h1 {
    font-size: 20px;
    font-weight: 700;
    color: var(--black);
    margin: 0;
  }

  button {
    white-space: nowrap;
    padding: 0.4rem 0.8rem;
    border: none;
    border-radius: 20px;
    background-color: var(--primary-color);
    font-size: 14px;
    color: var(--white);

    &:hover {
      background-color: var(--primary-colorHover);
    }
  }
}

.MapViewHotelData {
  overflow: hidden;
  border-radius: 12px;
  height: 200px;
  width: 200px;
}
.paddingMapView {
  background-color: var(--white);
  padding: 0 1rem;
  align-items: center;
  box-shadow: none;
}
.MapViewtopDivHotelContrr {
  padding: 0 0 0 0.5rem;
}

.primeLocDIv {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  p {
    white-space: nowrap;
    margin: 0;
    color: var(--text-grey);
    font-size: 10px;
    font-size: 400;
  }

  .leftLocDiv {
    display: flex;
    align-items: center;

    gap: 4px;

    img {
      height: 12px;
      width: 12px;
    }
  }
  .RightLocDiv {
    display: flex;
    align-items: center;

    gap: 4px;

    img {
      height: 11px;
      width: 11px;
    }
  }
}

.HotlMapViewDIv {
  top: 0.6rem;
  padding: 0px;
  right: 6px;
}

.hottleLeftDDtaa {
  .MapViewDiv {
    display: flex;
    gap: 5px;
    position: absolute;
    top: 0.6rem;
    left: 0.5rem;

    p {
      padding: 3px 5px;
      font-size: 12px;
    }
  }
}

.numbrRttingICn {
  height: 15px !important;
  width: 15px !important;
  padding: 0 !important;
  margin-right: 8px !important;
  border: none !important;
}

.mapSection {
  position: relative;
  height: calc(100vh -186px);
  padding-right: 0;
}

.mapControls {
  position: absolute;
  top: 10px;
  left: 3%;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1;
}
.backButtonMap,
.filterButtonMap {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: var(--white);
  padding: 4px 6px;
  border-radius: 12px;
  height: fit-content;

  img {
    height: 20px;
    width: 20px;
  }
}
.leftCOntnetTOp {
  .MpSecRattingDiv {
    padding: 0.4rem 0.4rem !important;
    background-color: var(--primary-color) !important;
    span {
      color: var(--white);
    }
  }
}

.backButtonMap {
  &:hover {
    background-color: var(--light-bg);
  }
}
.filterButtonMap {
  font-weight: 700;
  font-size: 22px;
  color: var(--black);
  padding: 0.4rem 1rem;
}

.filterIconBlackIcnn {
  margin-left: 10px;
}
.mapSection iframe {
  width: 100%;
  height: 100%;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

@media screen and (min-width: 1200px) {
  .mrginIGHt {
    margin-left: 5rem;
  }

  .MapViewtopDivHotelContrr {
    flex-grow: 1;
  }
}

@media screen and (max-width: 1200px) {
  .mrginIGHt {
    margin-left: 1.5rem;
  }
}

@media all and (max-width: 768px) {
  .mapSection {
    height: 300px;
    padding: 0;
    border-radius: 0;
  }

  .mrginIGHt {
    margin: 0;
  }

  .rowRvrs {
    flex-direction: column-reverse;
  }

  .locationHotelNameHead2 h1 {
    font-size: 18px;
  }

  .locationHotelNameHead2 img {
    width: 16px !important;
    height: 16px !important;
  }

  .mapSection iframe {
    border-radius: 0 !important;
  }

  .mapddedCard {
    flex-direction: row !important;
  }

  .MapViewHotelData {
    width: 200px;
    height: 200px;
    min-width: 200px;
  }

  .primeLocDIv {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }

  .middleDataHotelCOntnr h1 {
    font-size: 18px !important;
  }

  .hotlimglef2 {
    width: 150px;
    height: 150px;
  }

  .gm-style .gm-style-iw-d {
    max-height: 100% !important;
    overflow: hidden !important;
  }
}

@media all and (max-width: 576px) {
  .primeLocDIv {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
  }

  .middleDataHotelCOntnr h1 {
    font-size: 18px !important;
  }

  .hotlimglef2 {
    width: 150px !important;
    height: 150px !important;
    min-width: 150px !important;
  }

  .MapViewHotelData {
    width: 150px !important;
    height: 150px !important;
    min-width: 150px !important;
  }

  .shorByContent {
    & .shortByListadat {
      h1 {
        font-size: 16px;
      }
    }
  }

  .shorByContent {
    & .sortBybttn {
      & button {
        font-size: 12px;
        padding: 0.4rem 0.8rem;
      }
    }
  }

  .shorByContent {
    .shortByListadat {
      padding: 0.5rem 0;
    }
  }
}

@media all and (max-width: 480px) {
  .primeLocDIv {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    flex-wrap: wrap;
  }

  .middleDataHotelCOntnr h1 {
    font-size: 18px !important;
  }

  .hotlimglef2 {
    width: 120px !important;
    height: 120px !important;
    min-width: 120px !important;
  }

  .MapViewHotelData {
    width: 120px !important;
    height: 120px !important;
    min-width: 120px !important;
  }
}

@media all and (min-width: 767px) and (max-width: 1400px) {
  .responsivWidth {
    width: 100%;
  }
}

.hotlimglefMAp {
  width: 100%;
  height: 100%;
}

.hotlimglef2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardNewDiv {
  border: 1px solid var(--border-line-hotel);
  border-radius: 12px;
  padding: 1rem;
}

.mapddedCard {
  display: flex;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .mapddedCard {
    flex-direction: column;
  }
  .MapViewHotelData {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.gm-style .gm-style-iw-c {
  border-radius: 24px !important;
  max-height: 185px !important;
}

.SHOWaFTER400 {
  display: none;
}

@media screen and (max-width: 400px) {
  .middleDataHotelCOntnr {
    .hottlMiddleData {
      margin-bottom: 0;
    }
  }

  .hideaFTER400 {
    display: none;
  }

  .SHOWaFTER400 {
    display: block;
  }

  .rightSIdeData {
    & .bokkNowwBtn {
      h1 {
        font-size: 28px;
      }
    }
  }
}
