.offer_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  width: 100%;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

    /* transform: scale(1.05); */
  }
}

.OfferUnderDiv {
  display: flex;
  justify-content: center;
  height: 200px;
  span {
    padding: 0.1rem 0.8rem;
    position: absolute;
    display: flex;
    align-items: center;
    gap: 4px;
    top: 12%;
    left: 4%;
    color: var(--white);
    border-radius: 8px;
    font-size: 0.9rem;
    background-color: var(--primary-color);
  }
}

.OfferUnderDiv img {
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.offer_card_body {
  padding: 1rem;
  h5 {
    font-weight: 700;
    font-size: 20px;
    color: var(--black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    color: var(--text-grey);
    font-weight: 400;
    font-size: 14px;
  }
}

.offerCardSpan {
  margin-bottom: 0.9rem;
  span {
    padding: 0.5rem;
    border-radius: 20px;
    background-color: var(--light-orange);
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 600;
  }
}
.PriCeHottlDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LEftPriceDiv {
  p {
    margin: 0;
  }
}
.rightPriceDiv {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  small {
    font-size: 13px;
    font-weight: 400;
    color: var(--text-grey);
  }

  span {
    font-weight: 700;
    color: var(--black);
  }
}

.hotelAQI {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-bottom: 0.9rem;
  background-color: var(--light-blue);
  border-radius: 16px;
  padding: 0.5rem 1rem;

  p {
    display: flex;
    align-items: center;
  }
}

.aqiColor {
  background-color: var(--dark-green);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.aqitxt {
  font-size: 16px !important;
  color: var(--text-black) !important;
  line-height: 24px !important;
  margin: 0;
}

.absoHeartRed {
  position: absolute;
  top: 9%;
  right: 3%;

  img {
    height: 20px;
    width: 20px;
  }
}
.heartIcon {
  animation: scaleAndLift 2s infinite;
  transform-origin: center;
}
.GrayClass {
  filter: grayscale(1) !important;
  opacity: 0.7 !important;
}

.SOldOutClass {
  filter: grayscale(1) !important;
  opacity: 0.7 !important;
}

@keyframes scaleAndLift {
  0%,
  100% {
    transform: scale(0.5) translateY(0.5);
  }
  50% {
    transform: scale(1) translateY(-1px);
  }
}
