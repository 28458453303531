.logOutModlll {
  font-family: "inter";
  .MOdleCenterDttaa {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h4 {
    max-width: 70%;
    font-family: "inter";
    font-size: 28px;
  }

  button {
    white-space: nowrap;
    width: 100%;
    text-align: center;
    border: none;
    background-color: transparent;
    color: var(--primary-color);
    font-size: 24px;
    &:hover {
      color: var(--primary-colorHover);
    }
  }

  .logUtBtttn {
    width: 100%;
    padding-top: 0.6rem;
    border-top: 1px solid var(--border-line-hotel);
  }

  .NoBtttn {
    border-left: 1px solid var(--border-line-hotel);
  }
}
