.swagWllteHeading {
  margin-top: 2rem;
  h2 {
    font-weight: 700;
    font-size: 20px;
  }
}
.swagDIvBith {
  margin-bottom: 2rem;
}
.leftwalltDiv,
.RightwalltDiv {
  margin-top: 1rem;
  padding: 2rem 1.5rem;
  border: 1px solid var(--border-line-hotel);
  border-radius: 12px;
}

.leftwalltDiv {
  h2 {
    font-size: 24px;
    font-weight: 700;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    color: var(--text-grey);
    line-height: 30px;
  }
}

.walltDivimg {
  display: flex;
  overflow: hidden;
  position: relative;
  height: 200px;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  background: var(--primary-gradient-bg);
  h2 {
    padding-left: 1rem;
    color: var(--white);
    font-size: 45px;
  }
  img {
    height: 250px;
    width: auto;
  }
}

.leftImgWall {
  display: flex;
  position: relative;
  justify-content: end;
  height: 100%;
}

.wallTLIstData {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: var(--text-grey);
    line-height: 24px;
  }
}
.rightdivWalletcont {
  border-radius: 12px;
  display: flex;
  background-image: url("../../Assets/rightdivWallet.svg");
  justify-content: space-between;
  align-content: center;
  padding: 3rem 1rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.leftWallHist {
  h2 {
    color: var(--white);
    font-weight: 700;
    font-size: 30px;
    margin: 0px;
  }

  p {
    font-family: "inter";
    font-size: 13px;
    font-weight: 300;
    color: var(--white);
    margin: 0px;
  }
}
.wlltHisty {
  display: flex;
  align-items: center;
  button {
    font-size: 13px;
    font-weight: 500;
    border: 1px solid var(--white);
    padding: 0.6rem 1rem;
    border-radius: 20px;
    height: fit-content;
    color: var(--white);
    background-color: transparent;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      color: var(--light-bg);
    }
  }
}

.addMoneyDiv {
  margin-top: 2rem;

  h2 {
    font-size: 22px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: var(--text-grey);
    line-height: 24px;
  }
}

.coupon-card {
  background-color: var(--light-blue);
  border-radius: 12px;
  padding: 20px 10px;
  text-align: center;
  height: 100%;
  position: relative;
  h3 {
    color: var(--black);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
  }
  p {
    color: var(--primary-color);
    font-size: 14px;
    margin: 5px 0 0;
  }
}

.other-card {
  color: var(--text-black);
}

.addMoneyDiv {
  margin-top: 20px;
}

.leftCicleCoupn {
  position: absolute;
  top: 35%;
  left: -15px;
  padding: 0.9rem;
  width: fit-content;
  background-color: var(--white);
  border-radius: 50px;
}
.RightCicleCoupn {
  width: fit-content;
  position: absolute;
  top: 35%;
  padding: 0.9rem;
  border-radius: 50px;
  background-color: var(--white);
  right: -15px;
}

.nextButton {
  margin-top: 2rem;

  button {
    font-size: 14px;
    font-weight: 400;
    color: var(--white);
    background-color: var(--gray-faded);
    border: none;
    border-radius: 50px;
    padding: 0.6rem 2.5rem;
    transition: all 0.3s ease;
    &:hover {
      background-color: var(--black);
    }
  }
}

/*---------------Second Screen-----------------*/

.backBttn {
  display: flex;
  margin-top: 2rem;
  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    border: none;
    background-color: transparent;
  }
  img {
    height: 20px;
    width: 20px;
  }
  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
  }
}

.priceDivSencnd {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid var(--border-line-hotel);
}
.priceDivSencndImgbar {
  padding: 3rem 1rem;
  border-radius: 12px;

  background-image: url("../../Assets/screentwoDiv.png");
  justify-content: space-between;
  align-content: center;

  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    font-size: 30px;
    color: var(--white);
    margin: 0px;
  }

  p {
    font-family: "inter";
    font-size: 13px;
    font-weight: 300;
    color: var(--white);
    margin: 0px;
  }
}

.priceDivSencndImgbar input {
  height: 45px;
  border-radius: 12px;
  border: 1px solid #e1e1e1;
  padding: 0 0.5rem;
}
.priceDivSencndImgbar input:focus,
.priceDivSencndImgbar input:focus-visible {
  outline: none;
  border: none;
}

.underatascreentwo {
  margin-top: 1.5rem;
  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 34px;
    color: var(--text-black);
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: var(--text-grey);
    margin: 0;
    line-height: 24px;
  }

  li {
    font-size: 16px;
    margin-top: 0.3rem;
    font-weight: 400;
    color: var(--text-grey);
    margin-left: 0.5rem;
    line-height: 24px;
  }
}

.addMny {
  margin-top: 1.5rem;
  border: none;
  margin-bottom: 1rem;
  padding: 0.7rem 2.3rem;
  border-radius: 50px;
  font-size: 14px;
  color: var(--white);
  font-weight: 400;
  width: fit-content;
  background-color: var(--Light-black);
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--black);
  }
}

/*-----------------Last screen Payment wallte-----------*/

.contAMount {
  border-color: transparent;
}
.LastScreen {
  background-image: url("../../Assets/lastScreen.png");
  padding: 2rem 1rem;
  h2 {
    font-size: 23px;
  }
}

.dataShowTYpes {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;
  gap: 1rem;
  align-items: center;
  position: sticky;
  top: 100px;
  button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-grey);
    font-weight: 400;
    border-bottom: 1px solid transparent;
    padding: 0.5rem;
  }
}

.selectedDataTypes {
  font-weight: 600 !important;
  color: var(--primary-color) !important;
  border-bottom-color: var(--primary-color) !important;
}
.amountdata {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.leftSideAMountData {
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    height: 50px;
    width: 50px;
  }
}

.AmountSHowData {
  padding-bottom: 1rem;
  border-bottom: 1px solid #e1e1e1;
}

.leftAmoutnDatatitle {
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 17px;
    margin: 0;
  }
  p {
    margin: 0;
    padding-top: 2px;
    font-size: 13px;
    color: var(--text-grey);
    font-weight: 400;
  }
}

.rightAMountNumshow {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 17px;
    color: var(--red);
    font-weight: 500;
  }
  h2 {
    margin: 0px;
    font-size: 17px;
    color: var(--light-green);
    font-weight: 500;
  }

  p {
    font-size: 13px;
    color: var(--text-grey);
    font-weight: 400;
    margin: 0;
  }
}

@media screen and (min-width: 1200px) {
  .paddingLeftrIGHT {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media screen and (max-width: 1200px) {
  .paddingLeftrIGHT {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media screen and (max-width: 1090px) {
  .paddingLeftrIGHT {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (max-width: 991px) {
  .swagDIvBith {
    flex-direction: column-reverse;
  }

  .paddingLeftrIGHT {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media screen and (max-width: 576px) {
  .leftwalltDiv,
  .RightwalltDiv {
    padding: 1rem 0.7rem;
  }

  .priceDivSencnd {
    padding: 0.3rem;
  }
}
