.heroSectionHome {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: auto;
  height: 100vh;
  margin-bottom: 3rem;
  background-image: url("../../Assets/HomeHero.webp");
  background-size: cover;
  background-position: center;
  position: relative;
}

.OverFLowHome {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.721);
}

.heroUnderHead {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  width: 100%;
  align-items: center;
  position: relative;
  z-index: 2;
}

.HeroUnderHeading {
  margin-top: 1.6rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  color: var(--primary-color);

  h1 {
    font-size: 56px;
    .HeroUnderHeading {
      margin-top: 4rem;
    }
  }

  span {
    font-size: 56px;
    font-weight: 500;
    text-align: start;
    color: var(--white);
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .HeroUnderHeading h1,
  .HeroUnderHeading span {
    font-size: 48px;
  }

  .HeroUnderHeading h1 {
    margin-top: 3rem;
  }

  .HeroUnderHeading {
    padding: 0 1rem;
  }
}
.paymentText {
  justify-content: center;
  font-weight: 300;
  font-size: 14px;
  color: var(--white);
  margin-bottom: 1rem;
  gap: 1rem;
}
.HeroBottmContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding-bottom: 0.5rem;
  background-color: rgba(0, 0, 0, 0.258);
  padding: 0.5rem;
  margin-top: 2.5rem;
  font-weight: 300;
  align-items: center;
  gap: 1rem;
  color: var(--white);
  label {
    white-space: nowrap;
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  .heroSectionHome {
    height: 100%;
  }
  .paymentText {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .HeroUnderHeading {
    margin-top: 4.4rem;
    margin-bottom: 1rem;
  }

  .HeroUnderHeading {
    h1,
    span {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .HeroUnderHeading h1,
  .HeroUnderHeading span {
    font-size: 18px !important;
    line-height: 24px;
  }

  .paymentText {
    gap: 0.5rem;
  }

  .HeroUnderHeading h1 {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 991px) {
  .heroUnderHead {
    height: 92%;
  }
}

@media screen and (max-width: 556px) {
  .heroUnderHead {
    height: 100%;
  }
}

@media screen and (max-width: 576px) {
  .paymentText {
    display: none !important;
  }
}

.middleHeroContent {
  width: 80%;
}

@media screen and (max-width: 1600px) {
  .middleHeroContent {
    width: 90%;
  }
}

@media screen and (max-width: 1400px) {
  .middleHeroContent {
    width: 99%;
  }
}

@media screen and (max-width: 1200px) {
  .middleHeroContent {
    width: 99%;
  }

  .barWidthIn {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .noBorder {
    border: none !important;
  }
}

@media screen and (max-width: 991px) {
  .barWidthIn {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
