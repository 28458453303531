.filter-container {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  border: 1px solid #e1e1e1;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  font-size: 22px;
}

.filter-icon {
  width: 19px;
  height: 19px;
}

.filter-section {
  margin-top: 20px;
}

.filter-label {
  font-weight: 400;
  margin-bottom: 16px;
  display: block;
}

.slider {
  width: 100%;
  height: 8px;
  background: #f2f3f7;
  margin-top: 10px;
  border-radius: 20px;
}

.track {
  height: 8px;
  border-radius: 20px;
}

.track-filled {
  background-color: #ffb7aa;
}

.track-unfilled {
  background-color: #f2f3f7;
}

.thumb {
  height: 16px;
  width: 16px;
  background: #f86855;
  border-radius: 50%;
  top: -5px;
  cursor: pointer;
}

.price-labels {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
}

.price-labels span {
  font-size: 12px;
  color: var(--black);
  margin-right: 0.5rem;
}

.price-input {
  width: 60px;
  height: 36px;
  padding: 4px;
  border: 1px solid #edeeef;
  border-radius: 8px;
  text-align: center;
  font-size: 13px;
  color: #666;
  /* Hide up/down arrows */
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.price-input::-webkit-outer-spin-button,
.price-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.filter-input {
  width: 100%;
  height: 56px;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 14px;
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.chip {
  padding: 6px 12px;
  background: #fff;
  border: 1px solid #edeeef;
  color: #333;
  border-radius: 999px;
  font-size: 12px;
  cursor: pointer;
}

.chip.selected {
  background: #333;
  color: #fff;
}

.divider-line {
  border-top: 2px solid #e1e1e1;
  margin-top: 20px;
}

.pricing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clear-button {
  font-size: 0.9em;
  color: #f86855;
  cursor: pointer;
}

.filter_button {
  padding: 0.5rem;
  width: 230px;
  text-align: center;
  border: none;
  border-radius: 2rem;
  margin: 1rem;
  /* background-color: ; */
}

@media screen and (max-width: 400px) {
  .filter_button {
    margin: 1rem 0;
    width: 100%;
  }
}

.thumb-label {
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: 500;
  color: var(--black);
}
