.help-section {
  text-align: left;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.help-section h2 {
  font-family: Inter; /* Set font family */
  font-size: 24px; /* Font size */
  font-weight: 700; /* Font weight */
  line-height: 28.32px; /* Line height */
  letter-spacing: -0.5px; /* Letter spacing */
  text-align: left; /* Text alignment */
  margin-bottom: 1rem; /* Margin below the heading */
}

.help-section p {
  color: #666;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-bottom: 10px;
}

.contact-card {
  border: 1px solid #f86855;
  border-radius: 24px;
  background: conic-gradient(from 120deg, #FFF4F3, white);
  padding: 2rem;
  max-width: 1100px;
  margin: 0 auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-blend-mode: overlay;
}

.contact-card .contact-label,
.contact-card .email-label {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #000;
  text-align: left;
  margin-bottom: 1rem;
}

.contact-card .email-label {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #000;
  margin-bottom: 1rem;
  text-align: left;
}

@media screen and (max-width: 576px) {
  .contact-card .contact-label,
.contact-card .email-label {
  font-size: 30px;
}

.contact-card .email-label {
  font-size: 30px;

}
}

.contact-card .contact-detail {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  color: #555;
  margin-top: 5px;
  margin-left: 20px;
  margin-bottom: 20px;
}
