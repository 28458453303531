.success-modal {
  max-width: 400px;
  width: 100%;

  margin-top: 3rem;
  background-color: var(--white);
}

.pymentCOntnr {
  display: flex;
  overflow: hidden;
  padding: 1rem;
  justify-content: center;
  background-color: var(--light-blue);
}

.sucessPaymentModalDiv {
  .pymentCOntnr .modal-content {
    max-width: 400px;
    font-family: "inter";
  }
}

.SuccesssImg {
  padding-top: 2.5rem;
  margin-bottom: 1.5rem;

  img {
    padding: 0.7rem;
    border-radius: 50px;
    background-color: var(--light-green);
    height: 70px;
    width: 70px;
  }
}

.topSUcessData {
  position: relative;
  padding: 1.5rem;
}
.cirleLeftpaymnt {
  position: absolute;
  bottom: -25px;
  left: -12px;
  background-color: var(--light-blue);
  padding: 1rem;
  border-radius: 50px;
}
.cirleRIghtpaymnt {
  position: absolute;
  bottom: -25px;
  right: -12px;
  background-color: var(--light-blue);
  padding: 1rem;
  border-radius: 50px;
}

.success-modal__close {
  position: absolute;
  top: -7%;
  right: -12%;
  padding: 0.8rem;
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  img {
    height: 12px;
    width: 12px;
  }
}

.success-modal {
  position: relative;
}

.success-modal__body {
  text-align: center;
}

.success-modal__title {
  font-size: 22px;
  font-weight: "inter";
  font-weight: 600;
  margin: 1rem 0;
}

.success-modal__text {
  font-size: 15px;
  font-weight: 400;
  color: var(--text-grey);
}

.success-modal__amount {
  font-weight: 600;
  color: var(--black);
}

.success-modal__reference {
  font-size: 15px;
  font-weight: 400;
  color: var(--text-grey);
  margin-top: 0.5rem;
}

.success-modal__discount {
  padding-top: 1rem;
  padding-bottom: 1rem;

  margin-top: 1rem;
  h3 {
    font-size: 36px;
    font-weight: 700;
    color: var(--primary-color);
    margin: 0;
  }
  p {
    font-weight: 300;
    color: var(--text-grey);
    font-size: 13px;
    margin: 0px;
    padding-top: 0.2rem;
    padding-bottom: 2rem;
  }
}

@media all and (max-width: 498px) {
  .success-modal__close {
    right: -1%;
    top: -9%;
  }

  .success-modal {
    margin-top: 3.5rem;
  }
}
