.NewsLettercontainer {
  margin-top: 4rem;
  p {
    color: var(--text-grey);
    font-size: 18px;
    margin: 0;
  }
  h2 {
    font-weight: 600;
    margin: 0;
    color: var(--text-black);
    font-size: 35px;
  }
  span {
    font-weight: 600;
    margin: 0;
    font-size: 35px;
    color: var(--primary-color);
  }
}

@media screen and (max-width: 576px) {
  .NewsLettercontainer {
    h2 {
      font-size: 24px;
    }
    span {
      font-size: 24px;
    }
  }
}

.NewLetterBox {
  position: relative;
  margin-top: 2rem;
  padding: 2.5rem 1.7rem;
  border-radius: 40px;
  background-image: url("../../Assets/newsLetterCoverimg.svg");

  background-size: cover;
  background-position: center;
}
.joinCOm {
  padding: 2rem 0rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  h2 {
    color: var(--white);
    font-weight: 600;
  }
  span {
    text-align: center;
    font-weight: 400;
    color: var(--white);
  }
}
.NotiDiv {
  padding-top: 2rem;
  margin: 0;
  white-space: nowrap;
  input {
    border-radius: 10px;
    padding: 0.8rem;
    width: 250px;
    margin-right: 10px;
    border: none;
    font-size: 13px;
  }

  button {
    padding: 0.6rem 1.5rem;
    border-radius: 25px;
    font-size: 15px;
    background-color: var(--Light-black);
    color: white;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--black);
    }
  }
}

/*-----------------Image Circles-------------------------*/
.CirleCOntainr {
  justify-content: space-between;
}
.circleRightCenter,
.CirleRightTOp {
  overflow: hidden;
  overflow: hidden;
  height: 150px;
  width: 150px;

  /* margin-bottom: 2.5rem; */
  img {
    height: 100%;
    width: 100%;
  }
}

/*---------Left circles------*/
.CirleLeftTOp {
  overflow: hidden;
  top: -4.3rem;
  right: -2.5rem;
  position: absolute;
  height: 140px;
  width: 140px;
  img {
    height: 100%;
    width: 100%;
  }
}
.CirleLeftCenter {
  position: absolute;
  overflow: hidden;
  top: 9%;
  left: -3.1rem;
  height: 160px;
  width: 160px;
  img {
    height: 100%;
    width: 100%;
  }
}

.circleLeftBottm {
  /* left: 1rem; */
  right: -0.7rem;
  bottom: -7.5rem;
  position: absolute;
  overflow: hidden;
  height: 140px;
  width: 140px;
  img {
    height: 100%;
    width: 100%;
  }
}
/*---------Right circles------*/
.CirleRightTOp {
  left: -3rem;
  top: -4.8rem;
  position: absolute;
  overflow: hidden;
  height: 160px;
  width: 160px;
  img {
    height: 100%;
    width: 100%;
  }
}
.circleRightCenter {
  position: absolute;
  top: 45%;
  right: 1rem;
}
.circleRightCenter {
  height: 120px;
  width: 120px;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
  }
}
.circleRightBottm {
  left: -0.5rem;
  bottom: -8rem;
  position: absolute;
  overflow: hidden;
  height: 140px;
  width: 140px;
  img {
    height: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 873px) {
  .RightDiv,
  .LeftDiv {
    display: none;
  }
  .CirleCOntainr {
    justify-content: center;
  }
  .NewLetterBox {
    background-image: url("../../Assets/mobileNewLetterCOver.svg");
  }
}
@media screen and (max-width: 453px) {
  .NotiDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    button {
      margin-top: 1rem;
    }
  }
}
@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .CirleLeftTOp {
    top: -4.8rem;
    right: -4.2rem;
  }
  .circleLeftBottm {
    right: -2.6rem;
  }
}
@media screen and (min-width: 991px) and (max-width: 1200px) {
  .CirleLeftTOp {
    right: -6.5rem;
  }
  .circleRightBottm {
    left: -3.4rem;
  }
  .circleLeftBottm {
    right: -2.7rem;
  }
}
@media screen and (min-width: 922px) and (max-width: 992px) {
  .circleLeftBottm {
    right: 1.3rem;
  }
}
#communitylist .form-control {
  display: inline !important;
}

.displayinline {
  display: inline-grid;
}
.condition_error {
  color: red !important;
  font-size: 13px !important;
  display: none;
}
