.feedback-container {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    padding: 20px;
}

.feedback-header {
    background: linear-gradient(90deg, #ff7b67, #fc5c7d);
    padding: 20px;
    color: white;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.feedback-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.back-section {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.back-arrow {
    width: 24px;
    height: 24px;
}

.back-button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.feedback-title {
    margin-top: 1rem;
}

.feedback-title h1 {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
    margin: 0;
}

.feedback-title p {
    font-size: 14px;
    font-weight: 400;
    margin: 5px 0 0;
    width: 60%;
    color: #fff;

}

.feedback-image {
    width: 40%;
    object-fit: cover;
    border-radius: 8px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.feedback-image img {
    width: 100%;
    height: 100%;
}

.feedback-section {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
    border: 1px solid var(--border-line-hotel);
}

.feedback-section h2 {
    font-size: 24px;
    background: #FFF1EE;
    color: #F86855;
    font-weight: 500;
    margin-bottom: 20px;
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 8px;
}

.form-group {
    margin-bottom: 20px;
}

.question-label {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
}

.options-container {
    display: flex;
    flex-direction: column;
    /* Arrange items in a column */
    gap: 10px;
    /* Add space between options */
}

.radio-option {
    display: flex;
    align-items: center;
    gap: 5px;
}

.radio-option label {
    font-size: 16px;
    /* Set font size */
    font-weight: 400;
    color: #666;
    /* Set font color */
    cursor: pointer;
    /* Add pointer cursor for better UX */
}

.radio-option input[type="radio"] {
    appearance: none;
    /* Remove default styling */
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.radio-option input[type="radio"]:checked {
    border: 3px solid #000;
    /* Outer ring color */
    background-color: #F86855;
    /* Inner filled color */
}

.radio-option input[type="radio"]:hover {
    border-color: #F86855;
}

.textarea-input {
    width: 50%;
    padding: 10px;
    border: none;
    /* Removes all borders */
    border-bottom: 1px solid #ccc;
    /* Adds only a bottom border */
    resize: none;
    /* Disables resizing */
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    .textarea-input {
        width: 100%;
    }
    .feedback-title h1 {
        font-size: 28px;
      
    }
}@media screen and (max-width: 480px) {
    .feedback-title h1 {
        font-size: 24px;
       
    }
    .feedback-section h2 {
        font-size: 18px;
        
    }
}