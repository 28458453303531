/*------------------------------ Navbar Container -------------------------*/
.navBarCOntainer {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99;
}

.posFixed {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
}
.posFixedHome {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 399;
  width: 100%;
}

/*------------------------------ Logo Styling -------------------------*/
.LogoHome {
  height: auto;
  width: 115px;
}
.downLoadAppCOntainer {
  img {
    height: 18px;
    width: 18px;
    margin-right: 5px;
  }
  p {
    color: var(--black);
    margin: 0;
  }
}
/*------------------------------ Navbar Content -------------------------*/
.NavBarcontents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/*------------------------------ Navbar Text Styling -------------------------*/
.NavbartextHome,
.Navbartext {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;

  a {
    position: relative;
    text-decoration: none;
    color: white;
    padding-bottom: 5px;
    transition: color 0.4s ease;

    &::after {
      content: "";
      position: absolute;
      width: 0%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: var(--primary-color);
      transition: width 0.3s ease-in-out;
    }

    &:hover {
      color: var(--primary-color);

      &::after {
        width: 100%;
      }
    }
  }

  /*------------------------------ Icon Styling -------------------------*/
  i {
    margin-right: 5px;
  }
}

/*------------------------------ Navbar Links Wrapper -------------------------*/
.NavLinksWrapper {
  display: flex;
  justify-content: space-around;
  flex: 1;
}

/*------------------------------ Toggle Button -------------------------*/
.toggleSHOW {
  border: transparent;
}
.NAVcOTNNR {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}
.HeadLogNav {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
}

.cllpseNavshow,
.clipseachbar {
  display: flex;
  width: 100%;

  justify-content: space-between;
}

.clipseachbar {
  justify-content: center;
  align-items: center;
  /* overflow: auto; */
}
/*------------------------------ Navbar Top Styling -------------------------*/
.NaVbarTopHome {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;
  background-color: transparent;
  padding: 1rem 0.2rem;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}
.NaVbarTopHome.toggleHeaderActive {
  background-color: rgba(0, 0, 0, 0.523) !important;
  backdrop-filter: blur(5px);
}
.scrlllBg {
  background-color: rgba(0, 0, 0, 0.523) !important;
  backdrop-filter: blur(5px);
}
/*------------------------------ Navbar Top Styling (White Background) -------------------------*/
.NaVbarTop {
  background-color: var(--white);
  padding: 1rem 0.2rem;
  box-shadow: 0px 4px 12px 0px #0000001f;
  z-index: 1;
  position: relative;
}

/*------------------------------ Navbar Link Text Styling -------------------------*/
.Navbartext a {
  color: var(--black) !important;
}

/*------------------------------ Profile Section -------------------------*/
.profile-container {
  display: flex;
  align-items: center;
}

/*------------------------------ Profile Toggle -------------------------*/
.profile-toggle {
  text-decoration: none;
}

.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
}

.profile_Name {
  color: var(--black);
  font-weight: bold;
}

.profile_Name_home {
  color: var(--white);
}

.profile-toggle::after {
  display: none;
}

/*------------------------------ Custom Dropdown Arrow -------------------------*/
.custom-dropdown-arrow {
  margin-left: 8px;
  width: 12px;
  height: 12px;
}

/*------------------------------ Log Out Button -------------------------*/
.logOutButtnHeader {
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    border: none;
  }
}

/*------------------------------ Login Button -------------------------*/
.LogInButton {
  padding: 0.6rem 1.1rem;
  font-size: 16px;
  border-radius: 30px;
  width: fit-content;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
  border: 2px solid #f86855;
  background-image: linear-gradient(30deg, #f86855 50%, transparent 50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  transition: background 400ms ease-in-out;

  &:hover {
    background-position: 100%;
    color: #f86855;
  }
}

/*------------------------------ Dropdown Active Item -------------------------*/
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}

/*------------------------------ Pulse Animation -------------------------*/
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.searchBrrROw {
  margin: auto;
  min-width: 800px;
  background-color: transparent;
  /* overflow-x: auto;
  overflow-y: hidden; */
  padding: 10px;
  border: 1px solid var(--border-line-hotel);
  border-radius: 8px;
  display: flex;
  align-items: center;

  .input-group {
    flex-grow: 1;

    &:last-child {
      flex-grow: 0;
    }

    .input-group-text {
      background-color: transparent;
      border: none;
      padding: 0;

      i {
        margin-right: 0.5rem;
        font-size: 1.1rem;
        color: var(--black);
      }
    }

    .form-control {
      cursor: text;
      overflow: auto;
      background-color: transparent;
      border: none;
      padding: 0;
      font-weight: 400;
      font-size: 16px;
      color: var(--black);
      &:focus {
        box-shadow: none;
        border: none;
      }

      &::placeholder {
        color: var(--black);
      }
    }

    label {
      background-color: transparent;
      border: none;
      padding: 0;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .vr {
    border-left: 1px solid #e0e0e0;
    height: 24px;
    margin: 0 10px;
  }

  .btn-dark {
    background-color: var(--light-borwn);
    border: transparent;
    font-weight: 600;
    padding: 6px 20px;
    font-size: 16px;
    color: var(--white);
    border-radius: 20px;
    margin-left: 1rem;

    &:hover {
      background-color: var(--black);
    }
  }
}
.profileBardiv {
  display: flex;
  margin-left: 0.5rem;
  flex-wrap: nowrap;
  align-items: center;
}

/*------------------------------ Media Queries -------------------------*/
@media screen and (max-width: 992px) {
  /*------------------------------ Navbar Text Styling (Mobile) -------------------------*/
  .NavbartextHome {
    width: fit-content;
    margin-top: 0.5rem;
    gap: 3px;
  }
  .NAVcOTNNR {
    flex-direction: column;
    width: 100%;
  }

  .HeadLogNav {
    width: 100%;
    justify-content: space-between;
  }
  /* .cllpseNavshow,
  .clipseachbar {
    margin-top: 0.8rem;
  } */
  /*------------------------------ Navbar Content (Mobile) -------------------------*/
  .NavBarcontents {
    align-items: start;
    flex-direction: column;
  }

  /*------------------------------ Login Button (Mobile) -------------------------*/
  .LogInButton {
    margin-top: 1rem;
    padding: 0.6rem 1.9rem;
  }

  .clipseachbar {
    /* padding-bottom: 0.5rem; */
    justify-content: start;
  }
}

/*------Check Calander--------------------*/
@media screen and (max-width: 1117px) {
  .MapSearhBarHead {
    display: none;
  }
  .navbarToglelinks {
    display: flex;
  }
}
@media screen and (min-width: 1117px) {
  .MapSearhBarHead {
    display: flex;
  }
  .navbarToglelinks {
    display: none;
  }
}
.roomViewHeader {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;

  input {
    cursor: pointer !important;
  }
}
.HeaderdateCheck {
  button {
    background-color: transparent;
    border: none;
  }
}
.checkInCalanderHotelDetailHeader {
  display: flex;
  position: absolute;
  left: -0.5rem;
  top: 3rem;

  .react-datepicker {
    display: flex;

    gap: 0.5rem;
  }
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
