.ourServiceContainer {
  padding: 3rem 1.7rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-image: url("../../Assets/ourServiceCover.svg");
  background-size: cover;
  background-position: center;
}

.ourserviceDiv {
  .quickHotlsubhading p {
    color: var(--white);
    font-weight: 500;
    font-size: 35px;
    margin: 0;
  }
  h2 {
    font-weight: 400;
    margin: 0;
    color: var(--white);
  }
}

.service-item {
  background-color: var(--white);
  border-radius: 12px;
  padding: 0.7rem;
  overflow: hidden;
  width: 80%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* @media screen and (max-width: 767px) {
  .service-item {
    max-width: 100%;
  }
} */

.service-item img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.service-item img:hover {
  filter: grayscale(0%);
}

.service-item p {
  margin: 0;
  padding-top: 6px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  .service-item {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .quickHotlsubhading p {
    font-size: 24px !important;
  }

  .ourserviceDiv h2 {
    font-size: 18px !important;
  }
}
