@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.FaqCont {
  margin-top: 3rem;

  h2 {
    font-family: "Inter", sans-serif;
    margin-top: 0.3rem;
  }
  p {
    font-family: "Inter", sans-serif;
  }
}
.faqData {
  margin-top: 3rem;
}
.faqItem {
  background-color: var(--white);
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  box-shadow: var(--box-shadow);

  transition: all 0.3s ease;
}

.faqItem .faqQuestion {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2rem;

  transition: color 0.3s ease;
}
.faqItem .faqQuestion {
  p {
    font-weight: 600;
    font-size: 20px !important;
    color: var(--text-black) !important;
  }
}

.faqItem .faqQuestion .arrow {
  color: var(--black);
  transition: transform 0.3s ease;
}

.faqItem.open .faqQuestion .arrow {
  transform: rotate(180deg);
}
.faqItem .faqAnswer {
  transform-origin: top;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  padding-top: 0;
  transition: transform 0.3s ease, opacity 0.3s ease, padding-top 0.3s ease;
}
.faqItem.active .faqAnswer {
  transform: scaleY(1);
  opacity: 1;
}

.faqItem.open .faqAnswer {
  max-height: 200px;
  opacity: 1;
  padding-top: 1rem;
}

.faqItem.open .faqAnswer p {
  font-size: 16px !important;
  font-weight: 400;
  color: var(--text-grey);
  margin: 0;
  line-height: 24px;
}

.faqItem:hover {
  box-shadow: var(--box-shadow);
}
