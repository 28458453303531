.FlatDiscountDIv {
  background-image: url("../../Assets/flatDiscountHero.png");
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  h1 {
    text-align: center;
    max-width: 80%;
    font-size: 40px;
  }

  label {
    text-align: center;
    margin-top: 1rem;
    color: var(--white);
    font-weight: 400;
    font-size: 16px;
  }

  .FLastDiscountCOde {
    background-image: url("../../Assets/flatDiscountCopyImg.png");
    margin-top: 0.5rem;
    padding: 0.5rem;
    justify-content: center;
    .underCont {
      p {
        font-weight: 400;
        font-size: 12px;
      }

      h1 {
        font-weight: 600;
        font-size: 22px;
      }
    }

    .underCont2 {
      h1 {
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
}
.ApplyshowiNMobileOnly {
  display: none;
}
@media all and (max-width: 378px) {
  .FlatDiscountDIv {
    max-height: 100%;
  }
}
@media all and (max-width: 768px) {
  .FlatDiscountDIv {
    h1 {
      font-size: 32px;
    }
  }

  .ApplyshowiNMobileOnly {
    display: flex;
    justify-content: center;
  }

  .webLblAPply {
    display: none;
  }
}
