/*------------------------------ Footer Section -------------------------*/

.footerBggg {
  width: 100%;
  height: 50%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.FooterSection {
  margin-top: 4rem;
  position: relative;
  h5 {
    margin-bottom: 1.3rem;
    font-weight: 600;
  }

  /*------------------------------ Link Styling -------------------------*/

  .FooterDowndata {
    a {
      position: relative;
      text-decoration: none;
      color: white;
      padding-bottom: 5px;
      transition: color 0.4s ease;

      &::after {
        /*------------------------------ Underline Effect -------------------------*/
        content: "";
        position: absolute;
        width: 0%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: var(--primary-color);
        transition: width 0.3s ease-in-out;
      }

      &:hover {
        /*------------------------------ Hover Effect -------------------------*/
        color: var(--primary-color);

        &::after {
          width: 100%;
        }
      }
    }
  }

  /*------------------------------ List Data Styling -------------------------*/
  .ListData {
    gap: 1rem;

    li {
      font-weight: 500;
      margin-top: 0.7rem;
      text-decoration: none;
      list-style: none;
      font-size: 15px;
      color: var(--black);
    }

    a {
      font-weight: 400;
      color: var(--black);
      text-decoration: none;
    }
  }

  /*------------------------------ Social Media Links -------------------------*/
  .socialMediaLink {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    img {
      width: 32px;
    }
  }

  /*------------------------------ Social Icons Styling -------------------------*/
  .SocialIcons {
    margin-top: 0.5rem;
    gap: 0.8rem;
    img {
      transition: transform 0.3s ease;
    }
    img:hover {
      transform: scale(1.1);
    }
  }

  /*------------------------------ Left Side Content -------------------------*/
  .leftSide {
    display: flex;
    flex-direction: column;

    h5 {
      font-style: italic;
      padding-top: 0.8rem;
      font-size: 35px;
      color: var(--white);
    }

    p {
      padding-bottom: 0.8rem;
      font-size: 15px;
      color: var(--white);
      margin: 0;
    }
  }

  /*------------------------------ Right Side Content -------------------------*/
  .rightSide {
    p {
      font-size: 15px;
      color: var(--white);
      margin: 0;
    }

    img {
      margin-left: 5px;
      width: 32px;
    }
  }

  /*------------------------------ Footer Buttons -------------------------*/
  .ButtonsBottm {
    margin-top: 0.8rem;
    gap: 0.6rem;

    button {
      padding: 0.4rem 0.7rem;
      border: transparent;
      background-color: var(--white);
      color: var(--primary-color);
      border-radius: 12px;
      font-size: 15px;
      transition: background-color 0.3s ease, transform 0.3s ease,
        color 0.3s ease;

      &:hover {
        /*------------------------------ Button Hover Effect -------------------------*/
        background-color: var(--primary-colorHover);
        color: var(--white);
        transform: scale(1.05);
      }
    }
  }

  /*------------------------------ Icon Styling -------------------------*/
  .giveLInkIcom {
    height: 17px;
    width: 17px;
  }

  /*------------------------------ Give Link Section -------------------------*/
  .GiveLinkSection {
    display: flex;
    gap: 11px;
    align-items: center;

    label {
      font-size: 15px;
    }
  }

  /*------------------------------ Contact Section -------------------------*/
  .ContactSec {
    flex-wrap: wrap;

    p {
      font-size: 15px;
      white-space: nowrap;
    }
  }

  /*------------------------------ Footer Top Styling -------------------------*/
  .FOoterTOp {
    margin-bottom: 2.5rem;
    padding: 0.8rem 1.2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background-color: var(--primary-color);
  }

  /*------------------------------ Copyright Section -------------------------*/
  .copyRightText {
    p {
      color: var(--black);
      font-weight: 400;
    }
  }
}

@media screen and (min-width: 991px) {
  .p0left {
    padding-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .p0left {
    padding-left: 12px;
    margin-bottom: 1.5rem;
  }
}

.borderTopENd {
  border-top: 1px solid #e1e1e1;
  margin-top: 2.5rem;
  padding-top: 2.5rem;
}
