.contSearch {
  position: relative;
  cursor: pointer;
  p {
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0.5rem;
  }

  label {
    cursor: pointer;
    text-align: left;
  }
  input:focus {
    border: none;

    box-shadow: none;
    background-color: transparent;
  }
  input:disabled {
    background-color: transparent;
  }
}

.whatExpress {
  margin-top: 4rem;
  margin-bottom: 1.5rem;

  h2 {
    font-weight: 600;
  }
}

.card {
  border-radius: 10px;
}

.LisData {
  line-height: 2.4rem;
}
.VerticlIneHome {
  position: relative;
  border-right: 1px solid var(--white);
}
.VerticlIne {
  border-right: 1px solid var(--black);
}

.gustVeticalIne {
  border-right: 1px solid var(--black);
}
.gustVeticalIneHome {
  border-right: 1px solid var(--white);
}

.justiCOnt {
  margin: auto;
  /* justify-content: center; */
}

@media all and (max-width: 992px) {
  .VerticlIne,
  .VerticlIneHome {
    margin: 0px;
  }
  .justiCOnt {
    margin: 0px;
    /* justify-content: center; */
  }
  .gustVeticalIne,
  .gustVeticalIneHome {
    border-color: transparent;
  }

  .SearchButtn {
    margin-top: 1rem;
  }
}
@media all and (max-width: 576px) {
  .justiCOnt {
    justify-content: start;
  }

  .VerticlIne,
  .VerticlIneHome {
    border-color: transparent;
  }
  .gustVeticalIne,
  .gustVeticalIneHome {
    position: relative;
    border-color: transparent;
  }
}
.SearchButtn {
  border-color: transparent;
  border-radius: 40px;
  background-color: var(--primary-color);

  &:hover {
    background-color: var(--primary-colorHover);
  }
}

.HomeSearchbr {
  padding: 1rem 1.5rem;
  border-radius: 35px;
  background-color: rgba(255, 255, 255, 0.18);
  backdrop-filter: blur(12px);

  input {
    color: var(--light-grey) !important;
    background-color: transparent;
  }

  label {
    margin-top: 6px;
    font-weight: 500 !important;
    color: var(--white) !important;
  }

  i {
    color: var(--white);
  }

  p {
    font-weight: 500 !important;
    color: var(--white);
  }

  .SearchButtn {
    border: transparent;
    color: var(--black);
    background-color: var(--white);

    &:hover {
      color: var(--white);
      background-color: var(--primary-color);
    }
  }
}

.Searchbr {
  background-color: var(--cream-color);

  .form-control {
    background-color: transparent;
  }
}

.checkInCalander,
.checkInCalanderHotelDEtail,
.BookingCLanderHome,
.checkInCalanderHotelDetailHeader {
  display: flex;
  z-index: 8;
  gap: 1rem;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: var(--white);
  position: absolute;

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: var(--primary-color);

    &:hover {
      background-color: var(--primary-colorHover) !important;
    }
  }

  .react-datepicker__day-names {
    border: transparent;
    font-weight: 400;
  }

  .react-datepicker__header {
    border-bottom: transparent;
    background-color: transparent;
    padding-top: 1rem;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-weight: 400;
    font-family: "Inter", sans-serif;
  }
}
.BookingCLanderHome {
  width: fit-content;
  left: 44%;
  justify-content: end;
}
.checkInCalander {
  bottom: -21rem;
  left: -3.2rem;
}

.checkInCalanderHotelDEtail,
.BookingCLanderHome {
  bottom: -18.2rem;
}

.DayPicker-Day--selected {
  background-color: var(--primary-color);
  color: white;
  text-align: start;

  &:hover {
    background-color: var(--primary-color);
  }
}

.react-datepicker__header {
  h2 {
    font-weight: 500;
    font-size: 14px;
  }
}

.react-datepicker {
  /* padding: 1rem; */
  border-radius: 12px !important;
  border: 1px solid var(--border-line-hotel) !important;
  font-family: "Inter", sans-serif !important;
}

.AddroomDiv {
  min-width: 250px;
  background-color: var(--white);
  z-index: 8;
  position: absolute;
  bottom: -14rem;
  border: 1px solid var(--border-line-hotel) !important;
  padding: 1rem;
  border-radius: 12px;
}

.roomHead {
  font-weight: 700;
  color: var(--blue-color);

  h1 {
    font-weight: 700;
    font-size: 15px;
  }
}

.roomHeadData {
  text-align: start;
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  align-items: center;
}

.leftROomCOnt {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  h1 {
    color: var(--black);
    margin: 0px;
    font-size: 15px;
    font-weight: 500;
  }
  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    color: var(--text-grey);
  }
}
.rightROoomtCOnt {
  display: flex;
  gap: 0.7rem;
  align-items: center;
  button {
    padding: 0px;
    height: 30px;
    width: 30px;
    height: fit-content;
    font-size: 20px;
    border-radius: 20px;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }

  .plusBttm {
    border-color: transparent;
    color: var(--black);
    font-weight: 700;
    background-color: var(--cream-color);
    width: 33px;
    &:hover {
      background-color: var(--light-grey);
    }
  }
  .plusBttm.ENablePluse {
    color: var(--border-line-hotel);
    border: 1px solid var(--border-line-hotel);

    &:hover {
      background-color: var(--white);
    }
  }
  .minsssBttm {
    border-color: transparent;
    color: var(--black);
    font-weight: 600;
    background-color: var(--cream-color);

    &:hover {
      background-color: var(--light-grey);
    }
  }

  .minsssBttm.ENableMinusbtn {
    color: var(--border-line-hotel);
    border: 1px solid var(--border-line-hotel);

    &:hover {
      background-color: var(--white);
    }
  }

  label {
    color: var(--black) !important;
    font-weight: 700;
  }
}

.addrromBotttm {
  display: flex;
  cursor: pointer;
  margin-top: 1rem;
  align-items: center;

  p {
    font-weight: 600;
    margin: 0;
    color: var(--primary-color);
    font-size: 16px;
  }
  img {
    height: 22px;
    width: 22px;
    margin-right: 6px;
  }
}

.poplllerDesignation,
.poplllerDesignationhEADEER {
  /* bottom: -9.2rem; */
  /* left: 0.2rem; */
  z-index: 2;
  min-width: 250px;
  padding: 0.8rem 0.1rem 0.8rem 0.5rem;
  background-color: var(--white);
  border-radius: 12px;
  border: 1px solid var(--border-line-hotel);

  overflow: auto;
}

.poplllerDesignation {
  position: absolute;
  top: 112px;
  max-height: 250px;
}
.poplllerDesignationhEADEER {
  max-height: 400px;
  position: absolute;
  left: 0;
  top: 3rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.popHead {
  font-weight: 700;
  color: var(--blue-color);

  h1 {
    font-weight: 700;
    font-size: 15px;
  }
}

.citysSHow {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: auto;
  flex-wrap: nowrap;

  button {
    text-align: start;
    border: none;
    padding: 0.4rem 0.7rem;
    border-radius: 6px;
    background-color: transparent;
    font-size: 14px;
    color: var(--text-grey);
    font-size: 400;

    &:hover {
      color: var(--black);
      background-color: var(--light-blue);
    }
  }
}
/*________________responsive Media Querries_________________________*/

@media all and (max-width: 768px) {
  .SearchButtn {
    width: 95%;
  }
}
@media all and (min-width: 679px) and (max-width: 1000px) {
  .BookingCLanderHome {
    left: 19%;
  }
}
@media all and (max-width: 675px) {
  .BookingCLanderHome {
    left: 0;
    width: 100%;
    justify-content: center;
  }
}
@media all and (max-width: 597px) {
  .BookingCLanderHome {
    bottom: -14.2rem;
    left: 0;
    width: 100%;
    justify-content: center;
  }
}
@media all and (max-width: 540px) {
  .BookingCLanderHome {
    width: fit-content;
    bottom: -29.5rem;
  }
}
@media all and (max-width: 992px) {
  .checkInCalander {
    left: 0;
    bottom: -24rem;
  }

  .poplllerDesignation {
    width: 100%;
    left: 0;
    bottom: -19.5rem;
  }
}
@media all and (max-width: 575px) {
  .checkInCalander {
    flex-direction: column;
    left: 1rem;
    bottom: -46rem;
  }
  .poplllerDesignation {
    width: 100%;
    bottom: -33rem;
  }
}

.inOutimg {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.placeCOlorIn::placeholder {
  color: var(--light-grey);
}

.classHoriLine {
  border-bottom: 1px solid var(--border-line-hotel);
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
}

.nightsNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.nightsNumberLine {
  width: 1px;
  height: 80px;
  border-left: 1px solid var(--white);
}

.nightsNumberLineBlack {
  border-color: var(--black);
}

.nightN9 {
  background-color: #959595;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  position: absolute;
}

.nightN9Black {
  background-color: var(--black);
}

.nightN9 p {
  font-size: 16px;
  font-weight: 700 !important;
  margin: 0;
  color: var(--white);
}

.address {
  overflow: hidden; /* Prevents text overflow */
  text-overflow: ellipsis; /* Adds ellipsis if the text overflows */
}
