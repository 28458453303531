.foundDiv {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center the content horizontally */

  h1 {
    max-width: 60%;
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    color: var(--black);
  }
  p {
    max-width: 60%;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    color: var(--text-grey);
  }

  .contndDIvFound {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1024px) {
  .foundDiv h1 {
    font-size: 40px;
  }

  .foundDiv p {
    font-size: 20px;
    max-width: 80%;
  }
}

/* For Mobile Devices */
@media (max-width: 600px) {
  .foundDiv {
    margin-top: 1rem;
  }

  .foundDiv h1 {
    font-size: 30px;
    max-width: 80%;
  }

  .foundDiv p {
    font-size: 18px;
    max-width: 90%;
  }

  .contndDIvFound img {
    width: 60%;
    margin-bottom: 1rem;
  }
}
