/* LoginPage.scss */
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

.BothDivs {
  overflow: hidden;
  border-radius: 14px;
}
.loginModal {
  font-family: "Inter", sans-serif;
  display: flex;
  max-width: 100%;
  width: 100%;
  justify-content: center;

  .modal-body {
    position: relative;
    padding: 0;
  }

  .modal-content {
    position: relative;
    border-radius: 13px;
    background-color: transparent;
  }

  .modalCloseIcn {
    cursor: pointer;
    border-radius: 50px;
    right: -5.5%;
    height: 33px;
    width: 33px;
    top: -8%;
    position: absolute;
  }

  .fade {
    .modal-dialog {
      max-width: 700px;
      display: flex;
      justify-content: center;
    }
  }

  .leftSideLogin {
    margin: 0;
    padding: 1.6rem 2rem 1rem 2rem;

    background-image: url("../../Assets/modlLeftImg.png");
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;

    h1 {
      max-width: 200px;
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 1.3rem;
    }

    h2 {
      font-size: 19px;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .UnlkdBanifits {
      color: #fff;
      padding-bottom: 2rem;

      li {
        list-style: none;
        font-size: 15px;
        font-weight: 300;
        line-height: 2.2rem;
      }

      img {
        margin-right: 7px;
        height: 16px;
        width: 16px;
      }
    }
  }
  .rightTopContent {
    width: 100%;
  }
  .RightSideLogin {
    position: relative;

    padding: 1.6rem 2rem 1rem 1.2rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .LoginHEad {
      label {
        padding-top: 1rem;
        font-size: 18px;
      }
    }
  }
}

.loginModal.modal.fade .loginModal.modal-dialog {
  max-width: 750px;
  display: flex;
  justify-content: center;
}

.ByProccesing {
  display: flex;
  align-items: flex-start;
  padding-bottom: 0.6rem;
  span {
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: var(--text-grey);
  }
  img {
    margin-right: 5px;
    height: 23px;
    width: 23px;
  }
}

.cheknom {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;

  input {
    margin-right: 8px;
  }

  label {
    font-weight: 400;
    font-size: 14px;
    color: var(--text-grey);
    padding: 0px;
  }
}

.SendOtpButon,
.VerifyOtpButton {
  margin-top: 1.5rem;
  margin-bottom: 4.8rem;
  width: 100%;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 14px;
  color: var(--white);
  background-color: var(--Light-black);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--black);
  }
}
.Numberfill {
  margin-top: 1rem;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  border: 1px solid var(--border-line-hotel);
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: border 0.3s;
  overflow: hidden;
  &:hover {
    border: 1px solid var(--black);
  }
  .NumberInpt {
    border: none;
    box-shadow: none;
    outline: none;
    border-color: none;
  }

  &.Numbractive {
    border: 1px solid var(--black);
  }

  &::placeholder {
    color: var(--text-grey);
  }
}
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}

@media (max-width: 768px) {
  .RightSideLogin {
    padding: 2rem !important;
    border-radius: 12px;
  }
  .leftSideLogin {
    display: none;
  }

  .ByProccesing {
    margin-top: 2rem;
  }

  .BothDivs {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .loginModal .modalCloseIcn {
    right: 0%;
    top: -14%;
  }
  .OtpContainer,
  .RightSideLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.validation-message {
  font-size: 13px;
  color: var(--dark-red);
}
.conditionError {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  span {
    font-size: 14px;
    color: var(--primary-color);
  }
  img {
    height: 20px;
    width: 20px;
  }
}
.resetCoumeDOwn {
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;
  span {
    font-size: 14px;
  }
}
/*---------otp Contaioner-----*/
.OtpContainer {
  h2 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0.6rem;
  }
  label {
    margin-bottom: 1rem;
    padding-top: 1rem;
    font-size: 15px;
  }
  span {
    padding: 0px;
  }
}

.VerifyOtpButton {
  margin-top: 2rem;
  outline: none;
  border: none;
  margin-bottom: 0px;
}

.ResndOTP {
  margin-top: 1.2rem;
  text-align: center;
  width: 100%;
  color: var(--text-grey);
  font-size: 15px;
  background-color: transparent;
  border: none;

  &:hover {
    color: var(--black);
  }
}

.otpBoxfill {
  display: flex;

  input {
    width: 52px;
    height: 50px;
    text-align: center;
    font-size: 1.25rem;
    border: 1px solid var(--border-line-hotel);
    border-radius: 8px;
    transition: all 0.2s;
    outline: none;
    -moz-appearance: textfield;
    appearance: textfield;
    margin-right: 0.3rem;
    &:hover {
      border: 1px solid var(--black);
    }
    &:focus {
      border: 1px solid var(--dark-green);
    }
    &.hasValue {
      border-color: var(--dark-green);
    }
  }
}
.modlEditNumber {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin-left: 5px;
}
/*--------------------------New User-----------------------*/
.NewUserDiv {
  background-color: var(--white);
  padding: 1.5rem;
  h2 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 1rem;
  }
}

.FisTName {
  display: flex;

  margin-bottom: 0.5rem;
  flex-direction: column;
  label {
    margin-bottom: 0.5rem;
    color: var(--black);
    font-size: 16px;
    font-weight: 400;
  }

  label {
    span {
      margin: 0;
      padding: 0;
      color: var(--primary-color);
    }
  }

  input {
    font-weight: 400;
    border-radius: 6px;
    font-size: 15px;
    padding: 0.5rem;
    border: 1px solid var(--border-line-hotel);
    outline: none;
    transition: all 0.5s ease;
    &::placeholder {
      color: var(--text-grey);
    }

    &:hover {
      border: 1px solid var(--black);
    }
    &:focus {
      border: 1px solid var(--black);
    }
  }
}

.NexBtttn {
  margin-top: 1.7rem;
  button {
    border-radius: 50px;
    font-size: 14px;
    border: none;
    width: 100%;
    background-color: var(--Light-black);
    padding: 0.5rem 0.8rem;
    display: flex;
    justify-content: center;
    color: var(--white);
    &:hover {
      background-color: var(--black);
    }
  }
}

/*----------------Location Permmission----------*/
.locationPermission {
  background-color: var(--white);
  display: flex;
  padding: 1rem;
  justify-content: center;
  flex-direction: column;
  background-image: url("../../Assets/BgLocationPermisstion.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 20px;
  .LocationDIv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    img {
      height: 200px;
      width: 200px;
    }

    h2 {
      font-size: 26px;
    }

    p {
      padding: 0;
      font-weight: 400;
      color: var(--text-grey);
      font-size: 14px;
    }
  }
  .locatioPerrightdiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .perMissBtn {
    gap: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    justify-content: center;
    display: flex;

    button {
      width: 40%;
      border-radius: 50px;
      border-color: transparent;
      padding: 0.5rem 1.5rem;
      font-size: 14px;
      font-weight: 400;
    }

    .Allowbttn {
      background-color: var(--Light-black);
      color: var(--white);
      &:hover {
        background-color: var(--black);
      }
    }
    .CancelBttn {
      border: 1px solid var(--Light-black);
      color: var(--Light-black);
      background-color: transparent;

      &:hover {
        color: var(--black);
        border: 1px solid var(--black);
      }
    }
  }
}

/*-----------Welcome Page---------*/

.WelcomepAge {
  display: flex;
  justify-content: center;
  background-color: var(--primary-color);
  img {
    height: 200px;
    width: 200px;
  }

  h2 {
    color: var(--white);
    margin-bottom: 0.3rem;
    font-size: 26px;
    font-weight: 700;
  }

  p {
    padding: 0;
    font-weight: 400;
    color: var(--white);
    font-size: 14px;
  }

  .ImgWelcmContent {
    padding-bottom: 4rem;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
