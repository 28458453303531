.linear-container {
  height: 144px;
  background: linear-gradient(90deg, #ff758c, #f86855);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.background {
  background-color: #f7f8f9;
  height: calc(100vh - 90px);
  /* height: calc(100vh); */

  position: relative;
  z-index: 0;
  padding-top: 50px;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .background {
    padding-top: 60px;
  }
}
@media screen and (max-width: 576px) {
  .background {
    padding-top: 20px;
  }
}

/* Profile Section */
.boxContent {
  background-color: #ffffff;
  border-radius: 12px;
  justify-content: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;

  h3 {
    font-weight: 700;
    margin-bottom: 1rem;
  }
  input {
    margin-top: 0.6rem;
  }
}

.saveProfileDiv {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
  button {
    width: 100%;
    text-align: center;
  }
}

.tabrow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.profile-header {
  display: flex;
  align-items: center;
  padding: 20px;
  color: white;
  border-radius: 12px;
  padding-bottom: 0px;
}

.profile-info {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

.profile-info h2 {
  margin: 0;
  font-size: 1.2em;
  color: black;
}

.profile-completion {
  color: #ff4d4d;
  font-size: 0.9em;
  margin: 0;
}

.secure-message {
  white-space: nowrap;
  color: #28a745;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  padding-right: 10px;
}
@media screen and (max-width: 920px) {
  .secure-message {
    font-size: 0.6em;
    margin-top: 10px;
  }
}
.profile-tabs {
  overflow: auto;
  display: flex;
  gap: 20px;
  padding: 10px 0;
}

.tab-button {
  white-space: nowrap;
  background: none;
  border: none;
  padding: 10px 15px;
  font-size: 1em;
  color: #333;
  cursor: pointer;
  transition: color 0.1s, border-bottom 0.3s;
  border-bottom: 2px solid transparent;
}
@media screen and (max-width: 669px) {
  .tab-button {
    font-size: 14px;
  }
  .profile-tabs {
    gap: 0px;
    padding: 0px;
  }
}

.tab-button.active {
  color: #ff4d4d;
  border-bottom: 2px solid #ff4d4d;
}
@media screen and (max-width: 669px) {
  .tab-button.active {
    border-bottom: none;
  }
}

.tab-button:hover {
  color: #ff4d4d;
}

.name-row {
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

.form-section h3 {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}
.boxContent {
  inputContainer {
    margin-bottom: 1rem;
  }

  label {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--text-black);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  input {
    border-radius: 8px;
    border: 1px solid var(--border-color);
    padding: 10px 16px;
    font-size: 14px;
    width: 100%;
  }
  mobileinput {
    border: none !important;
    margin-top: 0px;
  }
}
.phone-input {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0 16px;
  width: 100%;
}

.flag-code {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 8px;
}

.flag-icon {
  width: 20px;
  height: 20px;
}

.phone-number-input {
  border: none;
  outline: none;
  flex: 1;
  font-size: 14px;
}

.whatsapp-notification {
  margin-top: 8px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  font-size: 12px !important;
  color: #666 !important;
  cursor: pointer;
}

.checkbox {
  display: none;
}

.checkmark {
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
  display: inline-block;
  margin-right: 8px;
  background-color: white;
  position: relative;
  /* Ensure this is set for the checkmark position */
}

/* Background color and checkmark when checkbox is checked */
.checkbox:checked + .checkmark {
  background-color: #f86855;
}

.checkbox:checked + .checkmark::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.email-input-container {
  display: flex;
  position: relative;
}

.email-input {
  width: 100%;
  padding-right: 60px;
  /* space for the verified badge */
}

.verified-badge {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px 8px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px !important;
}

.verified-badge.verify {
  background-color: #f8d7da;

  color: #721c24;
}

.verified-badge.verified {
  background-color: #d4edda;
  color: #155724;
}

.genderRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  button {
    white-space: nowrap;
  }
}

.gender-button,
.status-button {
  padding: 8px 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: none;
  cursor: pointer;
}

.gender-button.active,
.status-button.active {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
}

.gender-button:hover,
.status-button:hover {
  background-color: #ff4d4d;
  color: white;
}
.loca-icon {
  width: 20px;
  height: 20px;
}

.tab-content {
  padding: 20px;
  color: #333;
}

.accordionExpform {
  border-radius: 8px;
  border: 1px solid var(--border-color);
  width: 100%;
  height: 120px;
  position: relative;
}

.accordionExpform label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 16px;
}

.accordionExpform label p {
  color: var(--text-grey);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.accordionExpform .selectedImg {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.upImg {
  width: 32px;
  height: 32px;
  margin-bottom: 0.5rem;
}

.addharImags {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  gap: 1rem;
}

.crossImg {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 6px;
  right: 5px;
  cursor: pointer;
}

.crossImg img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .genderRow {
    gap: 0.5rem;
  }
}
react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.react-datepicker__input-container input:focus {
  outline: none;
  border-color: #007bff;
}

.onFOcusNoe:focus,
.onFOcusNoe:focus-visible {
  border: none !important;
  outline: none !important;
}

/**-----------Adhar Verify --------------*/

.variFyandAhdarDiv {
  border-radius: 8px;
  border: 1px solid var(--border-color);
  padding: 10px 16px;
  font-size: 14px;
  display: flex;
  align-items: center;

  input {
    margin: 0 !important;
    padding: 0;
    border: none !important;
    border-radius: 0 !important;
    &:focus {
      box-shadow: none !important;
    }
  }

  p {
    background-color: #f8d7da;
    margin: 0;
    color: #721c24;
    font-size: 11px;
    padding: 4px 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #f3c1c5;
    }
  }

  img {
    height: 20px;
    width: 20px;
  }
}
