/* Overlay */
.overlaySidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
}

/* Sidebar */
.CouponSidebar {
  width: 450px;
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(-400px);
  transition: transform 0.3s ease;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.overlaySidebar .CouponSidebar {
  transform: translateX(0);
}

.couponTop {
  background: linear-gradient(51.13deg, #ff758c -4.98%, #f86855 103.64%);
  height: 40%;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 2rem;
  border-top-left-radius: 12px;
}

.topImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-left-radius: 12px;
}

.couponInput {
  width: 100%;
}

.couponInput p {
  font-size: 40px;
  font-weight: 700;
  color: var(--white);
  line-height: 62px;
}

.couponInputContianer {
  width: 100%;
  height: 60px;
  border-radius: 16px;
  border: 1px solid #edeeef;
  background-color: var(--white);
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  padding: 10px;
}

.couponInputContianer input {
  width: 100%;
  height: 100%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border: none;
}

.couponInputContianer input:focus-visible {
  outline: none;
}

.applyCBtn {
  border: none;
  background-color: transparent;
  color: var(--text-black);
  font-size: 16px;
  font-weight: 500;
}

.couponContainer {
  padding: 1rem 2rem;
}

.couponHead {
  font-size: 25px;
  color: var(--text-black);
  font-weight: 600;
  line-height: 35px;
  margin-bottom: 1.5rem;
}

/* Coupon Styles */
.couponName {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.couponName img {
  width: 24px;
  height: 24px;
}

.couponCode {
  font-size: 18px;
  color: var(--text-black);
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 0;
}

.couponDetail {
  font-size: 14px;
  color: var(--text-grey);
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0;
}

.couponItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #edeeef;
  border-radius: 16px;
  padding: 0.8rem 1rem;
  margin-bottom: 1rem;
}

.couponList {
  height: calc(60vh - 75px);
  width: 100%;
  overflow-y: scroll;
  padding-right: 0.5rem;
}

.couponList::-webkit-scrollbar {
  width: 3px;
}

.couponList::-webkit-scrollbar-thumb {
  background: #d1d1d1 !important;
  border-radius: 5px !important;
}
