.HeroAqiSec {
  display: flex;
  background-image: url("../../Assets/AqiHeroCover.svg");

  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  padding: 2.5rem;
}

.headingAqiPage {
  h1 {
    color: var(--black);
    margin: 0;
    font-size: 45px;
  }
  span {
    font-weight: 600;
    margin: 0;
    font-size: 45px;
    color: var(--white);
  }
}

.bookursty {
  p {
    font-size: 20px;
    color: var(--white);
  }
  span {
    color: var(--dark-green);
  }
}

.exproreSaYtbn {
  display: flex;
  justify-content: flex-end;
  height: 100%;

  button {
    border: none;
    color: var(--black);
    font-size: 15px;
    background-color: var(--white);
    border-radius: 20px;
    padding: 0.5rem 1.6rem;

    &:hover {
      background-color: var(--light-bg);
    }
  }
}

@media screen and (max-width: 440px) {
  .exproreSaYtbn {
    margin-top: 1rem;
  }
}
