.traverl_agent_hero {
  background-image: url("../../Assets/traverl_agent_heroImg.svg");

  label {
    max-width: 60%;
  }
  .travllHeroHead {
    max-width: 70%;
    margin-bottom: 0.5rem;
    h1 {
      font-size: 56px;

      span {
        color: var(--primary-color);
      }
    }
  }
}
.HowItwrkTravl {
  padding-bottom: 8rem;
}

.ArrowImg {
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  align-items: flex-end;

  img {
    height: 80%;
    width: 100%;
    object-fit: contain;
  }
}

.BecomPtnrTrivlag {
  font-size: 16px;
  padding: 1rem 1.5rem;
}
.marginBttm {
  margin-bottom: 2rem;
}

@media (max-width: 991px) {
  .ArrowImgCont {
    display: none;
  }
}
@media (max-width: 900px) {
  .HowItwrkTravl {
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 600px) and (max-width: 1000px) {
  .traverl_agent_hero .travllHeroHead h1,
  .traverl_agent_hero .travllHeroHead span {
    max-width: 100%;
    font-size: 48px;
  }
}
@media (max-width: 600px) {
  .traverl_agent_hero .travllHeroHead h1,
  .traverl_agent_hero .travllHeroHead span {
    font-size: 36px;
  }
}

@media screen and (max-width: 768px) {
  .traverl_agent_hero {
    .travllHeroHead {
      max-width: 100%;
    }
    label {
      max-width: 100%;
    }
  }
}

.width80 {
  width: 80%;
}
