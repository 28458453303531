.simpleheader p {
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.32px;
  letter-spacing: 0;
  text-align: left;
  padding-left: 3%;
}

.da {
  display: flex;
  align-items: center;
  padding-left: 3.5%;
}
.da img {
  font-size: 20px; /* Adjust size of the arrow */
  margin-right: 10px; /* Adjust the spacing as needed */
}

.section h3 {
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0;
  text-align: left;
  padding-left: 5%;
  margin-bottom: 15px;
  margin-top: 3%;
}
