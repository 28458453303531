.refrerEarnBg {
  margin-top: 2rem;
  border-radius: 24px;
  background-color: var(--light-bg);
  height: auto;
  overflow: hidden;

  /*--------------------------Left Side Refer  HEro--------------------*/
  .lefRefer {
    padding: 2rem 3rem;
    h1 {
      font-size: 48px;
      font-weight: 700;
      color: var(--primary-color);
      margin: 0;
      font-family: "Satoshi";
    }

    p {
      margin-top: 0.5rem;
      font-size: 20px;
      font-weight: 400;
      color: var(--text-grey);
    }
  }
  /*--------------------------right Side Refer  HEro--------------------*/
  .rightRfrrSIde {
    display: flex;
    justify-content: flex-end;
    img {
      max-width: 100%;
      /* max-height: 100%; */
      max-height: 300px;

      width: auto;
      height: auto;
      object-fit: cover;
    }
  }
}

.ReferCOde {
  background-image: url("../../Assets/bgRefer.png");
  max-width: 267px;
  border-radius: 12px;
  margin-top: 1.5rem;
  background-repeat: no-repeat;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .underCont {
    display: flex;
    flex-direction: column;
    gap: 1px;
    p {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
      color: var(--white);
    }
    h1 {
      font-size: 24px;
      font-weight: 600;
      margin: 0;
      color: var(--white);
    }
  }

  .underCont2 {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h1 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      color: var(--white);
    }
  }

  .refrVericleLine {
    width: 2px;
    height: 50px;
    background-color: var(--white);
    margin: 0 15px;
  }
}
/*--------------------------CHeck blance div--------------*/
.checkSggBlanc {
  margin-top: 2rem;
  display: flex;
  padding: 0.6rem;
  border-top: 1px solid var(--border-line-hotel);
  border-bottom: 1px solid var(--border-line-hotel);
  align-items: center;
  gap: 1rem;
  justify-content: center;
  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
  }

  span {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 24px;
    margin: 0;
  }

  .coinsDIv {
    display: flex;
    align-items: center;
  }
}

/*----------Refer Via---------------------*/
.referViaDiv {
  margin-top: 2rem;
  padding: 1.5rem 2rem;
  border-radius: 24px;
  border: 1px solid var(--border-line-hotel);
  box-shadow: 0px 4px 4px 0px #0000000a;
  h1 {
    margin: 0;
    font-size: 35px;
    font-weight: 700;
    font-family: "Satoshi";
  }

  .SOcialMDiv {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;

    p {
      text-align: center;
      font-weight: 400;
      font-size: 20;
      margin-bottom: 0;
      margin-top: -6px;
    }
    img {
      height: 88px;
      width: 88px;
    }
  }

  .whatAPpdiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

/*--------------------------------How it work----------------------------*/
.howITWrk {
  margin-top: 2rem;

  h1 {
    font-size: 35px;
    font-weight: 700;
    font-family: "Satoshi";
    margin-bottom: 2rem;
  }

  .trmCOnd {
    display: flex;
    margin-top: 1rem;
    margin-left: 3%;
    gap: 1rem;
    align-items: center;
    span {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      color: var(--blue-text);
    }
  }
}

.listData {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  border-radius: 24px;
  border: 1px solid var(--border-line-hotel);
  padding: 1.5rem 2rem;
  box-shadow: 0px 4px 4px 0px #0000000a;

  .circleDiv {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    button {
      padding: 11px 18px;
      border-radius: 30px;
      width: fit-content;
      font-size: 24px;
      font-weight: 600;
      color: var(--text-grey);
      background-color: transparent;
      border: 1px solid var(--border-line-hotel);
    }

    p {
      color: var(--black);
      font-size: 24px;
      font-weight: 400;
      margin: 0;
    }
  }
}

/*------------------------------You are  yet to earn----------------------------*/

.youAReHead {
  margin-top: 2rem;
  h1 {
    font-size: 35px;
    font-weight: 700;

    font-family: "Satoshi";
  }
  p {
    font-weight: 400;
    font-size: 24px;
    margin: 0;
    font-family: "Satoshi";
    color: var(--text-black);
    padding-left: 0.3rem;
  }
}

/*-----------Ean 100 coins div---------*/
.EarndCOins {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 24px;
  border: 1px solid var(--border-line-hotel);
  box-shadow: 0px 4px 4px 0px #0000000a;

  padding: 1rem;

  .leftCOin {
    font-size: 42px;
    padding: 1rem 2rem;

    h1 {
      font-weight: 700;
      margin: 0;
      color: var(--black);
    }
    span {
      font-weight: 700;
      margin: 0;
      color: var(--primary-color);
    }
  }

  .RIghtCOin {
    display: flex;
    justify-content: end;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}

@media all and (max-width: 768px) {
  .refrerEarnBg {
    .rightRfrrSIde {
      display: none;
    }
  }
  .EarndCOins {
    .RIghtCOin {
      display: none;
    }
  }
}
