.bookDetailsContainer {
  border: 1px solid var(--border-line-hotel);
  border-radius: 12px;
  padding: 1rem;
  background-color: var(--white);
}

.booldetailTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-line-hotel);
  padding-bottom: 1rem;
}

.left_detail {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 100%;
}

.hotelImg {
  width: 98px;
  height: 98px;
  border-radius: 8px;
  overflow: hidden;
}

.hotelText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90px;
}

.hotelImg img {
  width: 100%;
  height: 100%;
}

.cardHeading {
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  color: var(--text-black);
}

.cardText {
  font-size: 14px;
  line-height: 21px;
  color: var(--text-grey);
  font-weight: 400;
  margin: 0;
}

.discount_price {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.discountTxt {
  font-size: 12px;
  color: var(--primary-color);
  background-color: #f868551f;
  border-radius: 8px;
  line-height: 15px;
  padding: 4px;
  margin: 0;
}

.newPriceDiv {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.oldPrice {
  font-size: 16px;
  line-height: 20px;
  color: var(--text-grey_dark);
  margin: 0;
  text-decoration: line-through;
}

.newPrice {
  color: var(--text-black);
  font-weight: 700;
  margin: 0;
}

.bookDetailsBottom {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}

.leftSection {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.bookingInfo {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0.5rem;
}

.bookingInfo p {
  color: var(--text-grey);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 0;
}

.cardicon {
  width: 24px;
  height: 24px;
}

.rightSection {
  display: flex;
  align-items: center;
}

.editBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: var(--light-bg);
  border: none;
  border-radius: 8px;
  height: 56px;
  width: 56px;
}

/* Check-in and App QR */
.checkInColumn {
  flex: 1;
  background: linear-gradient(to right, #fff1eb 30%, #ace0f9);
  padding: 1.5rem 2rem;
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkInCard {
  background: #ffffff;
  padding: 15px;
  border-radius: 8px;
  position: relative;
  border: 1px solid #edeeef;
  border-bottom: 3px solid #f86855;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selfBooking {
  position: absolute;
  margin-top: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  border-radius: 8px;
}

.selfBookingCard {
  border-bottom: 1px solid #edeeef !important;
  display: block !important;
}

.selfBookingBtns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.selfBookingBtns button {
  height: fit-content !important;
  width: 70px !important;
  padding: 0.3rem 0.8rem;
  font-size: 14px;
}

.checInLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cloudImg {
  width: 40px;
  height: 36px;
}

.completeText {
  font-size: 13px;
  color: var(--blue-color);
  margin: 0;
  font-weight: 500;
  cursor: pointer;
}

.errorMarkImg {
  width: 24px;
  height: 24px;
  margin-right: 0.3rem;
}

.gradientBottom {
  width: 80px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.cardLabel {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-black);
  line-height: 27px;
}

.checkInDescription {
  color: #555;
  margin: 5px 0;
}

.appQr {
  height: 100%;
}

.qrImage {
  height: 100%;
  width: 100%;
}

/* Early Late Section */
.earlyLateColumn {
  background-color: var(--white);
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  padding: 1.5rem 2rem;
}

.selectTimeWrapper {
  border: 1px solid #edeeef;
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--white);
}

.timeSelect {
  border: 1px solid #edeeef;
  height: 37px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 0.6rem;
  cursor: pointer;
  gap: 0.2rem;
  position: relative;
}

.timeSelect select {
  border: none;
  background-color: transparent;
  color: var(--text-grey);
  font-size: 14px;
  line-height: 21px;
  appearance: none;
  padding-right: 20px;
  z-index: 1;
  cursor: pointer;
}

.custom-select {
  appearance: none; /* Remove default arrow in most browsers */
  -webkit-appearance: none; /* Remove default arrow in Safari */
  -moz-appearance: none; /* Remove default arrow in Firefox */
}

.timeSelect select:focus {
  outline: none;
  border-color: #007bff;
}

.downArrow {
  position: absolute;
  right: 0.4rem;
  top: 8px;
}

.clockIcon {
  width: 20px;
  height: 20px;
}

.checkInTime {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

/* Meal section Styles */

.mealCheckbox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mealOption {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0.5rem;
}

.mealOption label {
  font-size: 16px;
  color: var(--text-black);
  display: flex;
  align-items: center;
}

.mealPrice {
  margin-left: auto;
  font-weight: 400;
  color: var(--text-grey);
  font-size: 14px;
  line-height: 21px;
  text-align: right;
}

.customCheckbox {
  display: none;
}

.customCheckboxIcon {
  width: 20px;
  height: 20px;
  border: 2px solid #6a6a6f;
  border-radius: 5px;
  display: inline-block;
  margin-right: 8px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.customCheckbox:checked + label .customCheckboxIcon {
  background-color: var(--primary-color, #4caf50);
  border-color: var(--primary-color, #4caf50);
  position: relative;
}

.customCheckbox:checked + label .customCheckboxIcon::after {
  content: "✓";
  font-size: 20px;
  color: white;
  position: absolute;
  top: -6px;
  left: 1px;
}

.noteImg {
  width: 24px;
  height: 24px;
}

.noteContainer {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-top: 0.8rem;
}

/* Discount, COin and Payment section */
.rightArrow {
  width: 24px;
  height: 24px;
}

.couponBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.couponBtnLabel {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-black);
  margin: 0;
}

.coinBlnc {
  background-color: var(--light-bg);
  border: none;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.swagCoins {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--dark-green);
}

.blueItalic {
  color: var(--blue-color);
  font-style: italic;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 0.8rem;
  margin-bottom: 0;
}

/* Bill Details Section */

.billDescp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.billLabl {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-black);
  margin-bottom: 0;
  line-height: 24px;
}

.billDescp .cardText {
  margin-bottom: 0;
}

.billLabl.total,
.cardText.total {
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: 600 !important;
}

.continueBtn_container {
  padding: 0 1.5rem;
  margin: 1.5rem auto;
}

/* ----------------------------------------------------COnfirm Booking Page Styles--------------------------------------- */
.bookingInfoFlex {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px !important;
}

.bookingInfoFlex .bookingInfo {
  padding: 0.3rem !important;
}

.detailsBottomSap {
  border-bottom: 1px solid var(--border-line-hotel);
  padding-bottom: 1rem;
}

.paymentNote p {
  font-size: 14px;
  color: var(--text-grey);
  font-style: italic;
  line-height: 21px;
}
.paymentNote {
  padding: 0 1.5rem;
}

/* ------------------------------------------Booking Recipt PAge Styles--------------------------------------- */

.gstBtn {
  background-color: var(--light-bg);
}

.popUpBtns {
  cursor: pointer;
}

.bookingId {
  display: flex;
  align-items: center;
}

.bookingId p,
.bookingId span {
  font-size: 20px;
  line-height: 30px;
}

.bookingId p {
  font-weight: 600;
  color: var(--text-black);
  margin: 0;
}

.bookingId span {
  font-weight: 400;
  color: var(--text-grey);
}

.bookingId img {
  width: 24px;
  height: 24px;
  margin-left: 0.5rem;
  cursor: pointer;
}

.bookingReciptCon {
  border-top-left-radius: 0;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.StatusMsg {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #0080001f;
  width: fit-content;
  padding: 1rem 1.5rem;
}

.StatusMsg h5 {
  margin: 0;
  color: var(--dark-green);
  font-weight: 600;
}

/* Check In Check Out Styles */
.checkin-out-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
}

.label {
  font-size: 16px;
  color: var(--text-black);
  margin: 0;
}

.date {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin: 0;
}

.time {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-grey);
  margin: 0;
}

.duration {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 20px;
}

.nights {
  background-color: #6a6a6f;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}

.duration::before,
.duration::after {
  content: "";
  flex: 1;
  height: 1px;
  width: 40px;
  border-top: 1px solid var(--text-grey_dark);
}

.reciptBtns {
  border: none;
  background-color: transparent;
}

.reciptBtns img {
  width: 36px;
  height: 36px;
}

.reciptBookingTxt {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.reciptBookingTxt p,
.reciptBookingTxt span {
  font-size: 20px;
  line-height: 30px;
  margin: 0;
}

.reciptBookingTxt p {
  color: var(--text-grey);
}

.reciptBookingTxt span {
  color: var(--dark-green);
  font-weight: 700;
}

.policyBtn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.policyBtn p {
  margin: 0;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.policyBtn img {
  width: 24px;
  height: 24px;
}

/* Modals Stylings */

.GSTModal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.GSTModal .modal-footer {
  border-top: none;
}

.inputContainer label {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--text-black);
  margin-bottom: 0.5rem;
}
.inputContainer input {
  border-radius: 8px;
  border: 1px solid var(--border-color);
  padding: 10px 16px;
  font-size: 14px;
  width: 100%;
}

.GSTModal .modal-content {
  padding: 2rem 1.5rem 1rem 1.5rem;
  background-color: #fff;
  border: none;
}
.helplineNo .helpTime {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: var(--text-grey);
  line-height: 30px;
}

.helplineNo .helplineNo {
  margin: 0;
  font-size: 16px;
  color: var(--text-black);
  line-height: 24px;
}

.helpCOntainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.helpCOntainer img {
  width: 120px;
  height: 120px;
}

.GSTModal .modal-header .btn-close {
  margin-top: -80px;
  margin-right: -30px;
}

/* -------------------------------------------CaNCEL bOOKING Page Styles-------------------------------- */

.cancelMsgs {
  background-color: var(--red-bg);
  border: 2px solid var(--light-red);
  border-radius: 12px;
  padding: 1rem 2rem;
}

.bookingCancelCon {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.cancelMsgs h5 {
  color: var(--light-red);
  font-weight: 600;
  line-height: 30px;
}

.cancelMsgs p {
  color: var(--text-grey);
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  margin: 0;
}

.sucssMgs {
  background-color: rgba(0, 128, 0, 0.04);
  border: 2px solid var(--dark-green);
  border-radius: 12px;
  padding: 1rem 2rem;
}

.sucssMgs p {
  color: var(--dark-green);
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  margin: 0;
}

.refundSucss {
  color: var(--dark-green);
  font-weight: bold;
  line-height: 24px;
  font-size: 16px;
  margin: 0;
}

.cnclBtns {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 !important;
  width: 40%;
  margin: 1rem 0 !important;
}

.cnclBtns button {
  height: 40px !important;
  font-size: 14px !important;
}

@media screen and (max-width: 950px) {
  .cnclBtns {
    width: 50%;
  }
}

@media screen and (max-width: 750px) {
  .cnclBtns {
    width: 70%;
  }
}

@media screen and (max-width: 550px) {
  .cnclBtns {
    width: 100%;
  }
}
@media screen and (max-width: 540px) {
  .timeSelect {
    margin-top: 0.6rem;
  }
}

.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-radio input[type="radio"] {
  display: none; /* Hide the default radio button */
}

.radio-checkmark {
  width: 16px;
  height: 16px;
  border: 2px solid var(--text-grey);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s;
}

.custom-radio input[type="radio"]:checked + .radio-checkmark {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.radio-checkmark::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--white);
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  display: none;
}

.custom-radio input[type="radio"]:checked + .radio-checkmark::after {
  display: block;
}

.cancelReasonsCon {
  justify-content: flex-start !important;
  gap: 0.5rem !important;
}

.reasonTextarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  resize: vertical;
  font-size: 1rem;
  font-family: inherit;
  outline: none;
}

.reasonTextarea:focus {
  box-shadow: none;
}

/* ------------------------------------------Cancelled Booking Page Styles----------------------------------------- */

.checkIcon {
  width: 18px;
  height: 18px;
  margin-right: 0.5rem;
}

.billDetails li {
  color: var(--text-grey);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1rem;
}

/* expres checkin forms  */

.accordionExp {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.accordionExp-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.guestNo {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-black);
  margin: 0;
}

.greenTick {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}

.accordionExp-body {
  padding: 20px 0;
  background-color: var(--white);
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.mobile-input {
  display: flex;
  align-items: center;
}

.flag-icon {
  margin-right: 8px;
}

.accordionExpform {
  border-radius: 8px;
  border: 1px solid var(--border-color);
  width: 100%;
  height: 120px;
  position: relative;
}

.accordionExpform label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 16px;
}

.accordionExpform label p {
  color: var(--text-grey);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.accordionExpform .selectedImg {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.upImg {
  width: 32px;
  height: 32px;
  margin-bottom: 0.5rem;
}

.addharImags {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.crossImg {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 6px;
  right: 5px;
  cursor: pointer;
}

.crossImg img {
  width: 100%;
  height: 100%;
}
.display_no {
  display: none;
}
.margibttm {
  padding-bottom: 1.5rem;
}
/*-------Pick Drop div------------*/
.pickUpLocationDIv,
.TimingDropDown {
  z-index: 4;
  background-color: var(--white);
  position: absolute;
  top: 2.7rem;
  left: 1px;
  padding: 0.5rem;
  border-radius: 12px;
  border: 1px solid var(--border-line-hotel);
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.pickUpLocationDIv.show,
.TimingDropDown.show {
  transform: scaleY(1);
  opacity: 1;
}
.TimingDropDown {
  min-width: 130px;
}
.pickUpLocationDIv button,
.TimingDropDown button {
  text-align: start;
  color: var(--text-grey);
  font-weight: 400;
  font-size: 14px;
  border: none;
  border-radius: 4px;

  background-color: transparent;
  &:hover {
    background-color: var(--light-blue);
  }
}

.timinBttnsw {
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  /* justify-content: center; */
  width: 100%;

  align-items: center;
  button {
    width: 50%;
  }
  span {
    border-radius: 5px;
    text-align: center;
    width: 50%;
    margin: 0;
    background-color: var(--light-borwn);
    color: var(--white);
    font-weight: 400;
    font-size: 14px;
  }
}
.timeSelect {
  button {
    white-space: nowrap;
    background-color: transparent;
    font-size: 14px;
    color: var(--text-grey);
    border: none;
    border-radius: 4px;
    margin-right: 13px;
    &:focus {
      box-shadow: none !important;
      border: none !important;
    }
  }

  img {
    transition: transform 0.3s ease-out;
  }
}

.rotateClass {
  transform: rotate(180deg);
}
/* _______________________________________________________________________ */

@media screen and (max-width: 991px) {
  .leftSection {
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .right_detail {
    display: none;
  }
}

@media screen and (max-width: 633px) {
  .rightSection {
    align-items: flex-start;
  }
}

@media screen and (max-width: 588px) {
  .left_detail {
    align-items: flex-start;
  }
}

@media screen and (max-width: 576px) {
  .hotelImg {
    width: 150px;
    min-width: 150px;
    height: 150px;
  }

  .continueBtn_container {
    flex-direction: column;
    padding: 0;
  }

  .continueBtn_container button {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .left_detail {
    flex-direction: column;
    width: 100%;
  }

  .hotelImg {
    width: 100%;
    min-width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .bookingInfo p {
    font-size: 14px;
  }

  .cardicon {
    width: 18px;
    height: 18px;
  }

  .hotelText {
    height: auto;
  }

  .pad000 {
    padding: 0;
  }

  .earlyLateColumn,
  .checkInColumn {
    padding: 1.5rem 1rem;
  }
}

.phonNoCl {
  padding-left: 4rem !important;
}

.flagIocnPos {
  position: absolute;
  top: 9px;
  left: 5px;
  z-index: 99999;
  width: fit-content !important;
}

.nobookingDiv {
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    height: 300px;
    width: 300px;
  }
}

.customPlace input {
  padding: 0.5rem;
  border: none;
  font-size: 14px;
  margin-top: 0.5rem;
  border-bottom: 1px solid var(--text-grey);
  transition: border-bottom 0.2s ease;
}

.customPlace input:focus {
  outline: none;
  border-bottom-color: #f86855;
}

/*--------------------------Adhar Varification  div css -------------------*/

.AdharVarinput {
  padding: 0.6rem;
  border-radius: 5px;
  border: 1px solid var(--black);
  margin-top: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .inputConyt {
    width: 90%;
    .adharInputClasss {
      padding: 0.2rem !important;
      border: none !important;
      font-size: 16px;
      color: var(--black);
      width: 100%;
      height: 100%;

      &:focus {
        box-shadow: none !important;
      }
    }
  }
  .inputConyt2 {
    p {
      color: var(--primary-color) !important;
      font-weight: 700 !important;
      margin: 0;
      cursor: pointer;
    }
  }
  img {
    height: 20px;
    width: 20px;
  }
}
.otpVarModal {
  .modal-header {
    h4 {
      font-size: 21px;
    }
  }
}
.varificatioNotpDiv {
  input {
    font-size: 19px;
    &:focus {
      box-shadow: none !important;
      border: 1px solid var(--primary-color);
    }
  }
}
.submtotpbttn {
  margin-top: 1rem;
  margin-left: 5px;
  button {
    height: fit-content;
    width: fit-content;

    padding: 0.5rem 2rem;
    font-size: 15px;
  }
}
.otpVaConditon {
  margin-top: 0.5rem;
  font-size: 14px;
  margin-left: 3px;
}

.guesDtlHedd {
  font-size: 16;
}
