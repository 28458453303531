section.blog {
    margin: 7rem 0 5rem 0;
  }
  
  section.blog .card {
    flex: 1;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    border-radius: 20px;
    background-color: transparent !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    border: none !important;
    transform: scale(0.9);
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    height: 100%;
  }
  
  section.blog .card:hover {
    transform: scale(1);
  }
  
  section.blog .card img {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    object-fit: cover;
  }
  
  section.blog .image-item {
    height: 100%;
  }
  
  section.blog .card-container {
    display: flex;
    justify-content: space-between;
  }
  
  section.blog .card {
    flex: 1;
    text-align: center;
    transition: box-shadow 0.3s, transform 0.3s;
    padding: 10px !important;
  }
  
  section.blog .card .image,
  section.blog .card img {
    max-width: 100%;
    height: 100%;
    margin-bottom: 10px;
    border-radius: 20px;
  }
  
  section.blog .card h6 {
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
  }
  
  .blogtext p {
    text-align: left !important;
    font-size: 14px !important;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
  }
  
  .blogtext_container {
    padding: 0 10px;
  }
  
  .blog_section h4 {
    font-weight: 600;
    letter-spacing: 1px;
    margin: 2rem 0 3rem 0;
    text-align: center;
    width: 70%;
  }
  
  .blog_section .learnmore {
    background-color: #ffe81a;
    border-radius: 8px;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
    padding: 5px;
  }
  
  @media screen and (max-width: 991px) {
    section.blog {
      margin: 5rem 0 5rem 0;
    }
  }
  
  @media screen and (max-width: 768px) {
    .blogtext p {
      text-align: left !important;
      font-size: 14px !important;
    }
  
    section.blog {
      margin: 4rem 0 4rem 0;
    }
  
    .blog_section h4 {
      margin: 2rem 0 3rem 0;
      font-size: 20px !important;
      width: 100%;
    }
  }
  
  @media screen and (max-width: 576px) {
    .blog_section h4 {
      margin: 2rem 0 3rem 0;
      font-size: 18px !important;
    }
  }
  
  .shoblog_container {
    margin: 3rem auto;
  }
  


  .shoblogHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .blogprofileinfo {
    display: flex;
    align-items: center;
    gap: 0.7rem;
  }
  
.blogcard-text {
  font-size: 16px;
}

  .blogprofileinfoText h5 {
    color: var(--primary-color);
    font-weight: 600;
    margin-bottom: 0.1rem;
  }
  
  .blogprofileinfoText p {
    font-size: 13px;
    margin: 0;
    color: rgba(109, 110, 118, 1);
  }
  
  .blogsocialicons {
    display: flex;
    gap: 0.5rem;
  }
  
  .blogsocialicons img {
    width: 2rem;
  }
  
  .blogheading1 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .blogheading1 ul {
    padding-left: 1rem;
  }
  
  .Robo_content {
    margin: 3rem auto 4rem auto !important;
  }
  
  .Robo_content p {
    font-size: 15px;
    line-height: 1.5rem;
  }
  
  .Robo_content .blogImg2 {
    margin: 2rem auto 3rem auto;
  }
  
  .blogImg11 img,
  .Robo_content .blogImg2 img {
    width: 100%;
  }
  
  .blogupfooter {
    text-align: center;
  }
  
  .blogupfooter h3 {
    font-weight: 500 !important;
  }
  
  .whatsnexttoreadcard {
    background: transparent !important;
    box-shadow: none !important;
    border: none !important;
  }
  
  .whatsnexttoreadcard:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25) !important;
  }
  
  .post_dateTimes {
    text-align: left;
    padding-left: 10px;
    margin-bottom: 0.2rem;
  }
  
  .post_dateTimes p {
    margin: 0;
    color: #4c4c4c;
  }
  
  .post_dateTimes span {
    color: #592ea9;
  }
  
  .blogSHowSEction {
    margin-top: 2rem !important;
  }
  
  .blogprofileinfo img {
    width: 3rem;
  }
  
  .blogdetails_icon_user {
    background: white;
    border-radius: 30px;
  }
  
  .condition_error {
    color: red;
    display: none;
  }
  